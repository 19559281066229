import React from 'react';
import { useTranslation } from 'react-i18next';
import Api from 'utils/api';
import useAuth from 'hooks/useAuth';
import CompanyForm from './CompanyForm';
import InterruptModal from 'components/InterruptModal/InterruptModal';

const CompanyLoader = () => {
    const { my, isLogged } = useAuth();
    const { t } = useTranslation();

    if (!isLogged || my.company || !my.permissions.includes('COMPANY_CREATE')) {
        return null;
    }

    return (
        <InterruptModal width={640} className="company-form">
            <h3>{t('company_information')}</h3>
            <CompanyForm
                onFinish={data => {
                    Api.company.create(data)
                    window.location.reload();
                }}
            />
        </InterruptModal>
    )
};

export default CompanyLoader;
