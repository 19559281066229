import React, { useEffect } from 'react';
import Api from 'utils/api';
import useNotify from 'hooks/useNotify';

const Errors = () => {
    const { toast } = useNotify();

    const numErr = (error: any) => {
        const text = error.response.data.message ||
            error.response.data.errors?.map((e: any, i: number) => <div key={i}>{e.field} {e.defaultMessage}</div>);
        toast.error(text, {
            title: 'SYSTEM ERROR'
        });
    };

    const networkErr = () => {
        toast.error('NETWORK ERROR');
    };

    useEffect(() => {
        Api.subscribe('onError400', numErr);
        Api.subscribe('onError500', numErr);
        Api.subscribe('onError503', numErr);
        Api.subscribe('onErrorNetwork', networkErr);
        // eslint-disable-next-line
    }, []);

    return null;
};

export default Errors;
