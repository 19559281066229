import React, { createContext, useContext, useState, useEffect } from 'react';
import Api from 'utils/api';

type SpinType = {
    loading: boolean,
    setLoading: (val: boolean) => void,
}

export const LoadingContext = createContext({} as SpinType);

const LoadingProvider = (props: any) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Api.subscribe('beforeCall', (config: any) => { if(!config.noSpinner) setLoading(true); });
        Api.subscribe('afterCall', () => setLoading(false));
    }, []);

    return (
        <LoadingContext.Provider value={{loading, setLoading}} {...props} />
    );
};

const useLoadingWatcher = () => useContext(LoadingContext);

export { LoadingProvider, useLoadingWatcher }
