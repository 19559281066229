import { AxiosResponse } from 'axios';
import { UserModel, UserInfoModel, ProfileModel } from 'models/User';
import { ApiBase } from 'utils/api';

type RolePatch = {
    type: string,
    rolePermissions: {
        uuid: string,
        permissions: string[],
    }[]
}

export type IUserApi = {
    all: () => Promise<AxiosResponse<UserModel[]>>,
    profile: () => Promise<AxiosResponse<ProfileModel>>,
    create: (data: UserInfoModel, lang: string) => Promise<AxiosResponse<UserModel>>,
    update: (data: UserInfoModel) => Promise<AxiosResponse<UserInfoModel>>,
    setRole: (id: string, data: RolePatch) => Promise<AxiosResponse<any>>,
    delete: (id: string) => Promise<AxiosResponse>,
}

export default class UserApi implements IUserApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    all = () => {
        return this.base.axios.get('/users', {noSpinner: true});
    };

    profile = () => {
        return this.base.axios.get<ProfileModel>(`/users/${this.base.profileId}/profile`);
    };

    create = (data: UserInfoModel, lang: string) => {
        return this.base.axios.post<UserInfoModel, AxiosResponse<UserModel>>('/users', {...data, lang});
    };

    update = (data: UserInfoModel) => {
        return this.base.axios.patch(`/users/${data.uuid}`, data);
    };

    setRole = (id: string, data: RolePatch) => {
        return this.base.axios.patch(`/users/${id}/roles`, data);
    };

    delete = (id: string) => {
        return this.base.axios.delete(`/users/${id}`);
    };
}
