import React  from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Settings } from 'luxon';

const HtmlSync = () => {
    const { i18n } = useTranslation();

    Settings.defaultLocale = i18n.language;

    return <Helmet htmlAttributes={{ lang : i18n.language, dir: i18n.dir() }} />;
};

export default HtmlSync;
