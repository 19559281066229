import { selectorFamily } from 'recoil';
import bidsActive from 'context/atoms/bidsActive';
import { BidStatus } from 'models/Bid';


const bidsByStatus = selectorFamily({
    key: 'bidsByStatus',
    get: (statuses: BidStatus[] | undefined) => ({get}) => {
        const list = get(bidsActive);

        if (statuses && statuses.length)
            return list.filter(item => statuses.includes(item.status));

        return list;
        }
    }
);

export default bidsByStatus;
