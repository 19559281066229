export enum UserType {
    Client = 'CLIENT',
    Manager = 'MANAGER',
    Admin = 'ADMIN',
}

export type ProfileModel = {
    uuid: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    balance: number,
    managers: ManagerModel[],
    plan: ProfilePlanModel,
}

export type UserBaseModel = {
    uuid: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
}

export type UserWithManagers = UserBaseModel & {
    managers: ManagerModel[],
}

export type UserInfoModel = UserBaseModel & {
    type: UserType,
    roles: RoleModel[],
}

export type UserModel = UserInfoModel & {
    balance: number,
    managers: ManagerModel[],
    plan: PlanModel,
}

export type RoleModel = {
    uuid: string,
    role: string,
    permissions: string[],
}

export type ManagerModel = {
    uuid: string,
    firstName: string,
    lastName: string,
    email: string,
}

export type PlanModel = {
    uuid: string,
    name: string,
    country: string,
    deposit: number,
    bidGroupConfigsLimit: number,
    bidGroupConfigBidsLimit: number,
    bidGroupConfigBidsWinLimit: number,
    bidAmountLimit: number,
    negotiationsPrice: number,
    dueDays: number,
    shippingPrice: number,
    transportPrice: number,
    servicePrice: number,
    parkingPrice: number,
    status: string,
    default: boolean,
}

export type ProfilePlanModel = {
    deposit: number,
    bidGroupConfigsLimit: number,
    bidGroupConfigBidsLimit: number,
    bidGroupConfigBidsWinLimit: number,
    bidAmountLimit: number,
    negotiationsPrice: number,
    dueDays: number,
    shippingPrice: number,
    transportPrice: number,
    servicePrice: number,
    parkingPrice: number,
}
