import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { GuestRoute, PrivateRoute } from 'components/CustomRoutes/PrivateRoute';

import LoginPage from 'pages/Auth/LoginPage';
import RegisterPage from 'pages/Auth/RegisterPage';
import ResetPasswordPage from 'pages/Auth/ResetPasswordPage';
import CreatePasswordPage from 'pages/Auth/CreatePasswordPage';
import ChangePasswordPage from 'pages/Auth/ChangePasswordPage';

const HelpPage = React.lazy(() => import('pages/Public/HelpPage'));
const NotFoundPage = React.lazy(() => import('pages/Errors/NotFoundPage'));

const Dashboard = React.lazy(() => import('pages/Dashboard/MainPage'));
const AuctionPage = React.lazy(() => import('pages/Dashboard/AuctionPage'));
const CompanySettingsPage = React.lazy(() => import('pages/Dashboard/CompanySettingsPage'));
const ConsigneesPage = React.lazy(() => import('pages/Dashboard/ConsigneesPage'));
const NotifyPartiesPage = React.lazy(() => import('pages/Dashboard/NotifyPartiesPage'));
const BankAccountsPage = React.lazy(() => import('pages/Dashboard/BankAccountsPage'));
const BidsPage = React.lazy(() => import('pages/Dashboard/BidsPage'));
const LotPage = React.lazy(() => import('pages/Dashboard/LotPage'));
const UserListPage = React.lazy(() => import('pages/Dashboard/UsersPage'));
const UserPage = React.lazy(() => import('pages/Dashboard/UserPage'));
const UserCreatePage = React.lazy(() => import('pages/Dashboard/UserCreatePage'));
const PlansPage = React.lazy(() => import('pages/Dashboard/PlansPage'));
const ProfilePage = React.lazy(() => import('pages/Dashboard/ProfilePage'));
const PrivacyPage = React.lazy(() => import('pages/Public/PrivacyPage'));
const TermsPage = React.lazy(() => import('pages/Public/TermsPage'));
const CreateCompanyPage = React.lazy(() => import('pages/Public/CreateCompanyPage'));
const ProductPage = React.lazy(() => import('pages/Dashboard/ProductPage'));
const ProductsPage = React.lazy(() => import('pages/Dashboard/ProductsPage'));
const PaymentsPage = React.lazy(() => import('pages/Dashboard/PaymentsPage'));
const InvoicePage = React.lazy(() => import('pages/Dashboard/InvoicePage'));
const InvoiceCreatePage = React.lazy(() => import('pages/Dashboard/InvoiceCreatePage'));
const InvoicesPage = React.lazy(() => import('pages/Dashboard/InvoicesPage'));
const ShippingAgentsPage = React.lazy(() => import('pages/Dashboard/ShippingAgentsPage'));
const ShippingAgentEditPage = React.lazy(() => import('pages/Dashboard/ShippingAgentEditPage'));
const ShippingAgentCreatePage = React.lazy(() => import('pages/Dashboard/ShippingAgentCreatePage'));
const StatusReportPage = React.lazy(() => import('pages/Dashboard/StatusReportPage'));
const TransportAgentsPage = React.lazy(() => import('pages/Dashboard/TransportAgentsPage'));
const TransportAgentEditPage = React.lazy(() => import('pages/Dashboard/TransportAgentEditPage'));
const TransportAgentCreatePage = React.lazy(() => import('pages/Dashboard/TransportAgentCreatePage'));
const NotificationsPage = React.lazy(() => import('pages/Dashboard/NotificationsPage'));
const PayersPage = React.lazy(() => import('pages/Dashboard/PayersPage'));
const AucPosPage = React.lazy(() => import('pages/Dashboard/AucPosPage'));

function Routes() {

    return (
        <Switch>
            <PrivateRoute exact path="/" component={Dashboard}/>

            {/* Requires authentication */}
            <PrivateRoute exact path="/auction" component={AuctionPage} permission="AUCTION_READ"/>
            <PrivateRoute path="/auction/lot/:id" component={LotPage} permission="AUCTION_READ"/>
            <PrivateRoute exact path="/auction/bids" component={BidsPage} permission="BID_READ"/>
            <PrivateRoute exact path="/bank-accounts" component={BankAccountsPage} permission="BANK_READ"/>
            <PrivateRoute exact path="/company" component={CompanySettingsPage} permission="COMPANY_READ"/>
            <PrivateRoute exact path="/users" component={UserListPage} permission="USER_READ"/>
            <PrivateRoute exact path="/user/new" component={UserCreatePage} permission="USER_CREATE"/>
            <PrivateRoute path="/user/:id" component={UserPage} permission="USER_READ"/>
            <PrivateRoute exact path="/plans" component={PlansPage} permission="PLAN_READ"/>
            <PrivateRoute exact path="/profile" component={ProfilePage}/>
            <PrivateRoute exact path="/payments" component={PaymentsPage} permission="TRANSACTION_READ"/>
            <PrivateRoute exact path="/products" component={ProductsPage} permission="PRODUCT_READ"/>
            <PrivateRoute path="/product/:id" component={ProductPage} permission="PRODUCT_READ"/>
            <PrivateRoute exact path="/invoices" component={InvoicesPage} permission="INVOICE_READ"/>
            <PrivateRoute exact path="/invoice/new" component={InvoiceCreatePage} permission="INVOICE_CREATE"/>
            <PrivateRoute path="/invoice/:id" component={InvoicePage} permission="INVOICE_READ"/>
            <PrivateRoute path="/shipping-agents" component={ShippingAgentsPage} permission="SHIPPING_AGENT_READ"/>
            <PrivateRoute exact path="/shipping-agent/new" component={ShippingAgentCreatePage} permission="SHIPPING_AGENT_CREATE"/>
            <PrivateRoute path="/shipping-agent/:id" component={ShippingAgentEditPage} permission="SHIPPING_AGENT_UPDATE"/>
            <PrivateRoute path="/status-report" component={StatusReportPage} permission="PRODUCT_READ"/>
            <PrivateRoute path="/transport-agents" component={TransportAgentsPage} permission="TRANSPORT_AGENT_READ"/>
            <PrivateRoute exact path="/transport-agent/new" component={TransportAgentCreatePage} permission="TRANSPORT_AGENT_CREATE"/>
            <PrivateRoute path="/transport-agent/:id" component={TransportAgentEditPage} permission="TRANSPORT_AGENT_UPDATE"/>
            <PrivateRoute path="/notifications" component={NotificationsPage} permission="COMPANY_READ"/>
            <PrivateRoute path="/consignees" component={ConsigneesPage} permission="CONSIGNEE_READ"/>
            <PrivateRoute path="/notify-parties" component={NotifyPartiesPage} permission="NOTIFY_PARTY_READ"/>
            <PrivateRoute path="/payers" component={PayersPage} permission="PAYER_READ"/>
            <PrivateRoute path="/auc-pos" component={AucPosPage} permission="AUCTION_POS_READ"/>

            {/* Only for quests */}
            <GuestRoute exact path="/login" component={LoginPage}/>
            <GuestRoute exact path="/register" component={RegisterPage}/>
            <Route exact path="/reset-pass" component={ResetPasswordPage}/>
            <GuestRoute exact path="/create-pass" component={CreatePasswordPage}/>
            <Route exact path="/change-pass" component={ChangePasswordPage}/>

            {/* Any pages */}
            <Route exact path="/help" component={HelpPage}/>
            <Route exact path="/privacy" component={PrivacyPage}/>
            <Route exact path="/terms" component={TermsPage}/>
            <Route exact path="/create-company" component={CreateCompanyPage}/>

            {/* Should be last */}
            <Route component={NotFoundPage}/>
        </Switch>
    )
}

export default Routes;
