import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Api from 'utils/api';

import AlertBox from 'components/Notifications/AlertBox';
import useNotify from 'hooks/useNotify';
import { nToBr } from 'utils/common';

interface PasswordFromProps {
    title: string,
    subTitle: string,
    buttonText: string,
    token: string | null | undefined,
}

const PasswordForm = (props: PasswordFromProps) => {
    const PASS_MIN_LENGTH: number = 8;
    const { t } = useTranslation('auth');

    const [ error, setError ] = useState(false);
    const [ lengthError, setLengthError ] = useState(false);
    const [ confirmError, setConfirmError ] = useState(false);

    const history = useHistory();
    const { alert } = useNotify();

    const [ pass, setPass ] = useState('');
    const [ confirmPass, setConfirmPass ] = useState('');
    const [ noToken, setNoToken ] = useState(false);

    useEffect(() => {
        if (!props.token) {
            setNoToken(true);
            enableErrorWithMessage(t('error_invalid_request_token'));
        }
        // eslint-disable-next-line
    }, []);

    const passwordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        disableAllErrors();
        setPass(e.target.value);
    };

    const confirmPasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        disableAllErrors();
        setConfirmPass(e.target.value)
    };

    const enableErrorWithMessage = (message: string) => {
        setError(true);
        alert.error(message, {id: 'password'});
    };

    const disableAllErrors = () => {
        alert.remove('password');
        setError(false);
        setLengthError(false);
        setConfirmError(false);
    };

    const onSubmitPassword = (e: React.FormEvent<HTMLFormElement>) => {
        disableAllErrors();
        e.preventDefault();

        if (pass !== confirmPass) {
            setConfirmError(true);
            enableErrorWithMessage(t('error_password_dont_match'));
            return;
        }

        if (pass.length < PASS_MIN_LENGTH) {
            setLengthError(true);
            enableErrorWithMessage(t('error_password_length'));
            return;
        }

        Api.auth.sendNewPass(pass, props.token as string)
            .then(() => {
                history.push('/login');
                alert.success(t('new_password_set'), {delay: 100});
            })
            .catch(() => {
                enableErrorWithMessage(t('error_invalid_request_token'))
            });
    };

    return (
        <div className="auth-form">
            <Form onSubmit={onSubmitPassword}>
                <div className="dirty">
                    <AlertBox variant="rounded" target="main" />
                </div>

                <h1>{props.title}</h1>

                <div className="subtitle">
                    {nToBr(props.subTitle)}
                </div>

                <Form.Group className="mb-4">
                    <Form.Label>{t('password')}</Form.Label>
                    <Form.Control
                        type="password"
                        id="password"
                        placeholder={t('password')}
                        required
                        isInvalid={lengthError}
                        onChange={passwordOnChange}
                    />
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Label>{t('confirm_password')}</Form.Label>
                    <Form.Control
                        type="password"
                        id="password_confirmation"
                        placeholder={t('confirm_password')}
                        required
                        isInvalid={confirmError}
                        onChange={confirmPasswordOnChange}
                    />
                </Form.Group>

                <div className="d-grid">
                    <Button
                        type="submit"
                        size="lg"
                        disabled={error || noToken}
                    >
                        {props.buttonText}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default PasswordForm;