import { AxiosResponse } from 'axios';
import NotifyPartyModel from 'models/NotifyParty';
import { ApiBase } from 'utils/api';

export type INotifyPartyApi = {
    get: (userId: string) => Promise<AxiosResponse<NotifyPartyModel[]>>,
    create: (userId: string, data: NotifyPartyModel) => Promise<AxiosResponse<NotifyPartyModel>>,
    update: (userId: string, data: NotifyPartyModel) => Promise<AxiosResponse<NotifyPartyModel>>,
    delete: (userId: string, notifyPartyId: string) => Promise<AxiosResponse>,
}

export default class NotifyPartyApi implements INotifyPartyApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    get = (userId: string) => {
        return this.base.axios.get(`/users/${userId}/notify-parties`, {noSpinner: true});
    };

    create = (userId: string, data: NotifyPartyModel) => {
        return this.base.axios.post(`/users/${userId}/notify-parties`, data);
    };

    update = (userId: string, data: NotifyPartyModel) => {
        return this.base.axios.put(`/users/${userId}/notify-parties/${data.uuid}`, data);
    };

    delete = (userId: string, notifyPartyId: string) => {
        return this.base.axios.delete(`/users/${userId}/notify-parties/${notifyPartyId}`);
    };
}
