import { atomFamily, selectorFamily } from 'recoil';
import { BidStatus } from 'models/Bid';
import { bidDatesFamily } from 'context/atoms/bidFilterFamily';
import Api from 'utils/api';

const bidsFamily = atomFamily({
    key: 'bids',
    default: selectorFamily({
        key: 'bids/default',
        get: (name: string) => async ({ get }) => {
            const dates = get(bidDatesFamily(name));
            let res = await Api.bids.get(
                Object.values(BidStatus),
                dates[0].toISOString().slice(0, 10),
                dates[1].toISOString().slice(0, 10)
            );

            return res.data.reverse();
        },
    }),
});

export default bidsFamily;
