import { AxiosResponse } from 'axios';
import { ManagerModel } from 'models/User';
import { ApiBase } from 'utils/api';

export type IManagerApi = {
    all: () => Promise<AxiosResponse<ManagerModel[]>>,
    getClients: (managerId: string) => Promise<AxiosResponse>,
    assign: (managerId: string, clientId: string) => Promise<AxiosResponse<any>>,
    unassign: (managerId: string, clientId: string) => Promise<AxiosResponse>,
}

export default class ManagerApi implements IManagerApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    all = () => {
        return this.base.axios.get(`/companies/${this.base.companyId}/managers`);
    }

    getClients = (managerId: string) => {
        return this.base.axios.get(`/managers/${managerId}/clients`);
    };

    assign = (managerId: string, clientId: string) => {
        return this.base.axios.post('/managers/assignclient', {managerId, clientId});
    };

    unassign = (managerId: string, clientId: string) => {
        return this.base.axios.delete(`/managers/${managerId}/clients/${clientId}`);
    };
}
