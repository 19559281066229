import { PlanModel, UserWithManagers } from 'models/User';
import LotModel from 'models/Lot';

export type BidGroupConfigModel = {
    uuid: string,
    name: string,
    winLimit: number,
    totalBids: number,
    bidsLimit: number,
}

export type BidModel = {
    uuid: string,
    status: BidStatus,
    amount: number,
    createdAt: string,
    updatedAt: string,
    bidGroupConfig: BidGroupConfigModel,
    lot: LotModel,
    client: BidClientModel,
    notFullyClosed: boolean, // TODO remove after api will return normal bids
}

export type BidClientModel = UserWithManagers & {
    bid: BidModel, // TODO remove bid from client model
    plan?: PlanModel, // TODO need normal models
}

export enum BidStatus {
    Draft = 'DRAFT',
    Ready = 'READY',
    Manual = 'MANUAL',
    Automatic = 'AUTOMATIC',
    Canceled = 'CANCELED',
    CanceledManually = 'CANCELED_MANUALLY',
    Outbid = 'OUTBID',
    Negotiation = 'NEGOTIATION',
    Win = 'WIN',
    WinNegotiation = 'WIN_NEGOTIATION',
    Lost = 'LOST',
}
