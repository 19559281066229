import { AxiosResponse } from 'axios';
import { InvoiceModel } from 'models/Invoices';
import { ApiBase } from 'utils/api';

export type IInvoiceApi = {
    get:  (invoiceId: string) => Promise<AxiosResponse<InvoiceModel>>,
    find: (status: string[], from: string, to: string) => Promise<AxiosResponse<InvoiceModel[]>>,
    create: (data: InvoiceModel) => Promise<AxiosResponse<InvoiceModel>>,
    update: (data: InvoiceModel) => Promise<AxiosResponse<InvoiceModel>>,
    paidByClient: (invoiceId: string) => Promise<AxiosResponse>,
    delete: (invoiceId: string) => Promise<AxiosResponse>,
}

export default class InvoiceApi implements IInvoiceApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    get = (invoiceId: string) => {
        return this.base.axios.get(`/companies/${this.base.companyId}/invoices/${invoiceId}`);
    };

    find = (status: string[], from: string, to: string) => {
        return this.base.axios.get(`/companies/${this.base.companyId}/invoices`, {
            params: {
                status,
                from,
                to,
            },
        });
    };

    create = (data: InvoiceModel) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/invoices`,
            {
                ...data,
                clientId: data.client.uuid,
                productIds: data.products.map(p => p.uuid),
            });
    };

    update = (data: InvoiceModel) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/invoices/${data.uuid}`,
            {
                ...data,
                clientId: data.client.uuid,
                productIds: data.products.map(p => p.uuid),
            });
    };

    paidByClient = (invoiceId: string) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/invoices/${invoiceId}/paid-by-client/${this.base.profileId}`);
    };

    delete = (invoiceId: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/invoices/${invoiceId}`);
    }
}
