import { Component } from 'react';
import { isMobile } from 'react-device-detect';

class IsMobile extends Component {
    componentDidMount() {
        document.body.classList.add(isMobile ? 'is-mobile' : 'is-desktop');
    }

    componentWillUnmount() {
        document.body.classList.remove(isMobile ? 'is-mobile' : 'is-desktop');
    }

    render() {
        return null;
    }
}

export default IsMobile;
