import { AxiosResponse } from 'axios';
import { CompanyData, CompanyModel, CreateCompanyData } from 'models/Company';
import AucPosModel from 'models/AucPos';
import { ApiBase } from 'utils/api';

export type ICompanyApi = {
    my: () => Promise<AxiosResponse>,
    createWithOwner: (data: CreateCompanyData) => Promise<AxiosResponse<CompanyModel>>,
    create: (data: CompanyData) => Promise<AxiosResponse<CompanyModel>>,
    update: (data: CompanyData) => Promise<AxiosResponse<CompanyModel>>,
    get: (uuid: string) => Promise<AxiosResponse>,
    setApiKey: (key: string) => Promise<AxiosResponse>,
    setStamp: (file: File) => Promise<AxiosResponse>,
    delStamp: (filename: string) => Promise<AxiosResponse>,
    setLogo: (file: File) => Promise<AxiosResponse>,
    delLogo: (filename: string) => Promise<AxiosResponse>,
    getPoSNumbers: () => Promise<AxiosResponse<AucPosModel[]>>,
    setPoSNumbers: (data: AucPosModel[]) => Promise<AxiosResponse>,
}

export default class CompanyApi implements ICompanyApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    my = () => {
        return this.base.axios.get('/companies');
    };

    create = (data: CompanyData) => {
        return this.base.axios.post('/companies', data);
    };

    createWithOwner = (data: CreateCompanyData) => {
        return this.base.axios.post('/companies/create-with-owner', data);
    };

    update = (data: CompanyData) => {
        return this.base.axios.put('/companies', data);
    };

    get = (uuid: string) => {
        return this.base.axios.get(`/companies/${uuid}`, {noSpinner: true});
    };

    setApiKey = (key: string) => {
        return this.base.axios.patch('/companies', {
            jpCenterApiKey: key
        });
    };

    setStamp = (file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.base.axios.post(`/companies/${this.base.companyId}/stamp`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    delStamp = (filename: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/stamp/${filename}`);
    };

    setLogo = (file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.base.axios.post(`/companies/${this.base.companyId}/logo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    delLogo = (filename: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/logo/${filename}`);
    };

    getPoSNumbers = () => {
        return this.base.axios.get(`/companies/${this.base.companyId}/auction-pos`, {noSpinner: true});
    };

    setPoSNumbers = (data: AucPosModel[]) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/auction-pos`, data);
    };
}
