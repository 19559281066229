import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Button, Form } from 'react-bootstrap';
import Api from 'utils/api';
import AlertBox from 'components/Notifications/AlertBox';
import useNotify from 'hooks/useNotify';

const ResetPasswordPage = () => {
    const [ error, setError ] = useState(false);
    const [ email, setEmail ] = useState('');
    const { t, i18n } = useTranslation('auth');
    const history = useHistory();
    const { alert } = useNotify();

    const onSubmitEmail = (e: any) => {
        setError(false);
        e.preventDefault();

        Api.auth.sendChangePassToken(email, i18n.language)
            .then(() => {
                history.push('/login');
                alert.success(t('password_email_sent'), {delay: 100});
            })
            .catch(reason => {
                setError(true);
                alert.error(t('account_not_found'));
            });
    };

    return (
        <div className="auth-form">
            <Helmet title={t('reset_password')} />

            <Form onSubmit={onSubmitEmail}>
                <div className="dirty">
                    <AlertBox variant="rounded" target="main" />
                </div>

                <h1>{t('reset_password')}</h1>

                <div className="subtitle">
                    {t('back_to_login')} <Link to="/login">{t('click_here')}</Link>
                </div>

                <Form.Group className="mb-4">
                    <Form.Label>{t('email')}</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder={t('your_email')}
                        required
                        isInvalid={error}
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Form.Group>

                <div className="d-grid">
                    <Button type="submit">{t('reset_password')}</Button>
                </div>
            </Form>
        </div>
    );
};

export default ResetPasswordPage;
