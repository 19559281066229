import { ReactNode } from 'react';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import modal, { ModalProps } from 'context/atoms/modal';
import { replaceItemAtIndex } from 'utils/common';

const useModal = () => {
    const setModal = useSetRecoilState(modal);
    const history = useHistory();

    const showModal = (component: ReactNode, modalProps: ModalProps = {}) => {
        modalProps.id = modalProps.id || Math.random().toString();

        setModal(old => [
            ...old.map(m => ({ ...m, show: false })),
            { component, modalProps, show: true, page: history.location.pathname + history.location.search }
        ]);

        history.push(history.location);
    };

    const hideModal = () => history.goBack();

    const _hideLastModal = () => setModal(old => {
        let temp = replaceItemAtIndex(old, old.length-1, {...old[old.length-1], show: false});

        if (temp.length > 1)
            temp = replaceItemAtIndex(temp, old.length-2, {...old[old.length-2], show: true});

        setTimeout(() => setModal(old => old.slice(0, old.length-1)), 250);
        return temp;
    });

    const _clearModals = () => setModal([]);

    return { showModal, hideModal, _hideLastModal, _clearModals };
}

export default useModal;
