import { atom, selector } from 'recoil';
import { UserModel } from 'models/User';
import Api from 'utils/api';

const userList = atom<UserModel[]>({
    key: 'userList',
    default: selector({
        key: 'usersList/default',
        get: async () => {
            try {
                return (await Api.users.all()).data;
            } catch (e) {
                return [];
            }
        }
    }),
});

export default userList;
