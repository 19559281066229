import { atomFamily, selectorFamily } from 'recoil';
import { TransactionsList } from 'models/Transactions';
import Api from 'utils/api';

const transactionFamily = atomFamily<TransactionsList, string>({
    key: 'transactionFamily',
    default: selectorFamily({
        key: 'transactionFamily/default',
        get: (userId: string) => async () => {
            try {
                const data = (await Api.transactions.getTransactions(userId, '9999-12-31T23:00:00.000000')).data;
                return { list: data, hasNextPage: data.length > 0 };
            } catch (err) {
                return { list: [], hasNextPage: false };
            }
        }
    }),
});

export default transactionFamily
