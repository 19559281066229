import React from 'react';
import { useRecoilState } from 'recoil';
import { ToastContainer } from 'react-bootstrap';
import toastList from 'context/atoms/toastList';
import CustomToast from 'components/Customized/CustomToast';

const ToastBox = () => {
    const [toasts , setToasts] = useRecoilState(toastList);

    const remove = (id: string) => {
        setToasts(toasts.filter(toast => toast.id !== id));
    };

    return (
        <ToastContainer aria-live="polite">
            {toasts.map(item => <CustomToast key={item.id} {...item} onClose={remove} />)}
        </ToastContainer>
    );
};

export default ToastBox;
