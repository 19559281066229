import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import PasswordForm from 'components/PasswordForm/PasswordFrom';
import { useQueryParam, StringParam } from 'use-query-params';

const ChangePasswordPage = () => {
    const { t } = useTranslation('auth');
    const [ token ] = useQueryParam('token', StringParam);

    return (
        <>
            <Helmet title={t('change_pass_title')}/>

            <PasswordForm
                buttonText={t('change_pass_button')}
                subTitle={t('change_pass_subtitle')}
                title={t('change_pass_title')}
                token={token} />
        </>
    );
};

export default ChangePasswordPage;
