import { AxiosResponse } from 'axios';
import { AgentContactModel, TransportAgentModel, RouteModel } from 'models/TransportAgent';
import { AgentInfoModel } from 'models/ShippingAgent';
import { ApiBase } from 'utils/api';

export type ITransportAgentApi = {
    get: () => Promise<AxiosResponse<TransportAgentModel[]>>,
    create: (data: TransportAgentModel) => Promise<AxiosResponse<TransportAgentModel>>,
    update: (data: AgentInfoModel) => Promise<AxiosResponse>,
    delete: (id: string) => Promise<AxiosResponse>,

    createRoute: (agentId: string, data: RouteModel) => Promise<AxiosResponse<RouteModel>>,
    updateRoute: (agentId: string, data: RouteModel) => Promise<AxiosResponse>,
    removeRoute: (agentId: string, portId: string) => Promise<AxiosResponse>,

    createContact: (agentId: string, data: AgentContactModel) => Promise<AxiosResponse<AgentContactModel>>,
    updateContact: (agentId: string, data: AgentContactModel) => Promise<AxiosResponse>,
    removeContact: (agentId: string, contactId: string) => Promise<AxiosResponse>,
}

export default class TransportAgentApi implements ITransportAgentApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    get = () => {
        return this.base.axios.get(`/companies/${this.base.companyId}/transport-agents`, {noSpinner: true});
    };

    create = (data: TransportAgentModel) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/transport-agents`, {
            ...data,
            routes: data.routes.map(r => ({...r, portId: r.loadingPort.uuid})),
        });
    };

    update = (data: AgentInfoModel) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/transport-agents/${data.uuid}`, data, {noSpinner: true});
    };

    delete = (id: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/transport-agents/${id}`);
    };

    createRoute = (agentId: string, data: RouteModel) => {
        return this.base.axios.post(
            `/companies/${this.base.companyId}/transport-agents/${agentId}/routes`,
            {...data, portId: data.loadingPort.uuid},
            {noSpinner: true}
        );
    };

    updateRoute = (agentId: string, data: RouteModel) => {
        return this.base.axios.patch(
            `/companies/${this.base.companyId}/transport-agents/${agentId}/routes/${data.uuid}`,
            {...data, portId: data.loadingPort.uuid},
            {noSpinner: true}
        );
    };

    removeRoute = (agentId: string, portId: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/transport-agents/${agentId}/routes/${portId}`, {noSpinner: true});
    };

    createContact = (agentId: string, data: AgentContactModel) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/transport-agents/${agentId}/contacts`, data, {noSpinner: true});
    };

    updateContact = (agentId: string, data: AgentContactModel) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/transport-agents/${agentId}/contacts/${data.uuid}`, data, {noSpinner: true});
    };

    removeContact = (agentId: string, contactId: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/transport-agents/${agentId}/contacts/${contactId}`, {noSpinner: true});
    };
}
