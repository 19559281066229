import React, { ReactNode } from 'react';

type InterruptModalProps = {
    width: number,
    height?: number,
    children: ReactNode,
    className?: string,
}

const InterruptModal = (props: InterruptModalProps) => {
    const style = {
        maxWidth: props.width + 'px',
        height: props.height ? props.height + 'px': undefined,
    };

    return (
        <div className={`interrupt-modal ${props.className ?? ''}`}>
            <div className="inner" style={style}>{props.children}</div>
        </div>
    );
};

export default InterruptModal;
