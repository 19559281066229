import { AxiosResponse } from 'axios';
import { Message, MessagesAuthModel, MessagesEventsModel } from 'models/Messages';
import { ApiBase } from 'utils/api';

export type IMessageApi = {
    auth: (data: MessagesAuthModel) => Promise<AxiosResponse>,
    last: () => Promise<AxiosResponse<MessagesEventsModel>>,
    prev: (date: string) => Promise<AxiosResponse<MessagesEventsModel>>,
}

export default class MessageApi implements IMessageApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    auth = (data: MessagesAuthModel) => {
        return this.base.axios.post(`/broadcasting/auth`, data, {noSpinner: true});
    };

    last = () => {
        return this.prev('9999-12-31 23:00:00.000000');
    };

    prev = (date: string) => {
        return this.base.axios.get(`/messages?cursor=${date}`, {noSpinner: true})
            .then(res => {
                res.data.events = res.data.events.map((e: Message) => {
                    e.payload.readable = JSON.parse(e.payload.readable);
                    return e;
                });

                return res;
            });
    };
}
