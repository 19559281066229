import { AxiosResponse } from 'axios';
import {
    ProductBaseModel,
    ProductModel,
    ShippingData,
    TransportData,
    PurchaseDetails,
    SellingDetails,
    RefundDetails,
    ShipmentModel,
    ShipmentDocuments,
    ProductStatus,
} from 'models/Product';
import { CarDetails, CarInformation } from 'models/Lot';
import { ApiBase } from 'utils/api';
import AuctionApi from 'utils/Api/Auction';

export type IProductApi = {
    get:  (uuid: string) => Promise<AxiosResponse<ProductModel>>,
    find: (status: string[], from: string, to: string) => Promise<AxiosResponse<ProductModel[]>>,
    create: (data: ProductBaseModel) => Promise<AxiosResponse<ProductModel>>,
    update: (data: ProductModel) => Promise<AxiosResponse<ProductModel>>,
    delete: (productId: string) => Promise<AxiosResponse>,
    setStatus: (productId: string, status: ProductStatus) => Promise<AxiosResponse>,

    updateShipping: (productId: string, data: ShippingData) => Promise<AxiosResponse>,
    updateTransport: (productId: string, data: TransportData) => Promise<AxiosResponse>,
    updatePurchase: (productId: string, data: PurchaseDetails) => Promise<AxiosResponse>,
    updateSelling: (productId: string, data: SellingDetails) => Promise<AxiosResponse>,
    updateRefunds: (productId: string, data: RefundDetails) => Promise<AxiosResponse>,
    attachNotifyParty: (productId: string, notifyPartyId: string) => Promise<AxiosResponse>,

    uploadExportCertificate: (productId: string, file: File) => Promise<AxiosResponse>,
    updateCarDetails: (lotId: string, data: CarDetails) => Promise<AxiosResponse>,
    updateFullChassis: (lotId: string, chassis: string) => Promise<AxiosResponse>,

    auctionPaid: (productId: string, date: string) => Promise<AxiosResponse>,
    orderTransport: (transportAgentId: string, productIds: string[]) => Promise<AxiosResponse<string[]>>,
    orderBooking: (shippingAgentId: string, loadingPortId: string, destinationPortId: string, productIds: string[]) => Promise<AxiosResponse<string[]>>,

    makeShipment: (data: ShipmentModel, productIds: string[]) => Promise<AxiosResponse>,
    updateShipment: (data: ShipmentModel, productIds: string[]) => Promise<AxiosResponse>,
    shipments: (from: string, to: string) => Promise<AxiosResponse<ShipmentModel[]>>,
    shipmentsActive: () => Promise<AxiosResponse<ShipmentModel[]>>,
    shipmentDocsSent: (shipmentId: string) => Promise<AxiosResponse>,
    uploadBL: (shipmentId: string, file: File) => Promise<AxiosResponse<ShipmentDocuments>>,
    uploadED: (shipmentId: string, file: File) => Promise<AxiosResponse<ShipmentDocuments>>,
    removeShipment: (shipmentId: string) => Promise<AxiosResponse>,

    importAutocom: (file: File) => Promise<AxiosResponse>,
    loadCarInfo: (make: string, vin: string) => Promise<AxiosResponse<CarInformation>>,

    uploadPhotos: (lotId: string, files: File[]) => Promise<AxiosResponse>,
}

export default class ProductApi implements IProductApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    get = (uuid: string) => {
        return this.base.axios.get(`/companies/${this.base.companyId}/products/${uuid}`)
            .then(res => {
                if (res.data.lot)
                    res.data.lot = AuctionApi.formatLot(res.data.lot)

                return res;
            });
    };

    find = (status: string[], from: string, to: string) => {
        return this.base.axios.get(`/companies/${this.base.companyId}/products`, {
            params: {
                status,
                from,
                to,
            },
        }).then(res => {
            res.data = res.data.map((p: ProductModel) => {
                if (p.lot)
                    p.lot = AuctionApi.formatLot(p.lot)

                return p;
            });

            return res;
        });
    };

    create = (product: ProductBaseModel) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/products`, product);
    };

    update = (product: ProductModel) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/products/${product.uuid}`,
            {
                ...product,
                clientId: product.client?.uuid,
            });
    };

    setStatus = (productId: string, status: ProductStatus) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/products/${productId}/status`, {status});
    };

    delete = (productId: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/products/${productId}`);
    };

    updateShipping = (productId: string, data: ShippingData) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/products/${productId}/shipping-details`, data);
    };

    updateTransport = (productId: string, data: TransportData) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/products/${productId}/transport-details`, data);
    };

    updatePurchase = (productId: string, details: PurchaseDetails) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/products/${productId}/purchase-details`, details);
    };

    updateSelling = (productId: string, details: SellingDetails) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/products/${productId}/selling-details`, details);
    };

    updateRefunds = (productId: string, data: RefundDetails) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/products/${productId}/refund-details`, data);
    };

    attachNotifyParty = (productId: string, notifyPartyId: string) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/products/${productId}/notify-parties/${notifyPartyId}`);
    };

    uploadExportCertificate = (productId: string, file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.base.axios.post(`/companies/${this.base.companyId}/products/${productId}/export-certificate`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    updateCarDetails = (lotId: string, data: CarDetails) => {
        data.fullChassisNumber = data.fullChassisNumber?.toUpperCase();
        return this.base.axios.patch(`/auctions/lots/${lotId}/details`, data);
    };

    updateFullChassis = (lotId: string, chassis: string) => {
        return this.base.axios.patch(
            `/auctions/lots/${lotId}/full-chassis`,
            {fullChassis: chassis.toUpperCase()},
            {noSpinner: true}
        )
    };

    auctionPaid = (productId: string, date: string) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/products/${productId}/auction-paid`, {date})
    };

    orderTransport = (transportAgentId: string, productIds: string[]) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/transport-agents/${transportAgentId}/order`, {
            productUuids: productIds,
        })
    };

    orderBooking = (shippingAgentId: string, loadingPortId: string, destinationPortId: string, productIds: string[]) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/shipping-agents/${shippingAgentId}/order`,
            {
                loadingPortId,
                destinationPortId,
                productUuids: productIds,
            })
    };

    makeShipment = (data: ShipmentModel, productIds: string[]) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/shipments`, {
            ...data,
            agentId: data.agent.uuid,
            loadingPortId: data.loadingPort.uuid,
            destinationPortId: data.destinationPort.uuid,
            productIds,
        })
    };

    updateShipment = (data: ShipmentModel, productIds: string[]) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/shipments/${data.uuid}`, {
            ...data,
            agentId: data.agent.uuid,
            loadingPortId: data.loadingPort.uuid,
            destinationPortId: data.destinationPort.uuid,
            productIds,
        })
    };

    shipments = (from: string, to: string) => {
        return this.base.axios.get(`/companies/${this.base.companyId}/shipments?from=${from}&to=${to}`)
    };

    shipmentsActive = () => {
        return this.base.axios.get(`/companies/${this.base.companyId}/shipments/active`, {noSpinner: true})
    };

    shipmentDocsSent = (shipmentId: string) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/shipments/${shipmentId}/documents-sent`)
    };

    uploadBL = (shipmentId: string, file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.base.axios.post(
            `/companies/${this.base.companyId}/shipments/${shipmentId}/bill-of-loading`,
            formData,
            {headers: {'Content-Type': 'multipart/form-data'}}
        )
    };

    uploadED = (shipmentId: string, file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.base.axios.post(
            `/companies/${this.base.companyId}/shipments/${shipmentId}/export-declaration`,
            formData,
            {headers: {'Content-Type': 'multipart/form-data'}}
        )
    };

    removeShipment = (shipmentId: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/shipments/${shipmentId}`)
    };

    importAutocom = (file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.base.axios.post(`/companies/${this.base.companyId}/products/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    loadCarInfo = (make: string, vin: string) => {
        return this.base.axios.get(`/auctions/car-info?brand=${make}&chassisNumber=${vin}`);
    };

    uploadPhotos = (lotId: string, files: File[]) => {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }

        return this.base.axios.post(
            `/auctions/lots/${lotId}/photos`,
            formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };
}
