import { DateTime } from 'luxon';
import { AxiosResponse } from 'axios';
import { BidLogModel, UserLogBaseModel } from 'models/Logs';
import { ApiBase } from 'utils/api';

export type ILogsApi = {
    bids: (lotId: string) => Promise<AxiosResponse<BidLogModel[]>>,
    users: (userId: string) => Promise<AxiosResponse<UserLogBaseModel[]>>,
}

export default class LogsApi implements ILogsApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    bids = (lotId: string) => {
        return this.base.axios
            .get<BidLogModel[]>(`/logs/${this.base.companyId}/bids/${lotId}`, {noSpinner: true})
            .then(res => {
                res.data.forEach((row: any) => {
                    row.executedAt = DateTime.fromISO(row.executedAt);
                })

                return res;
            });
    };

    users = (userId: string) => {
        return this.base.axios
            .get<UserLogBaseModel[]>(`/logs/${this.base.companyId}/users/${userId}`, {noSpinner: true})
            .then(res => {
                res.data.forEach((row: any) => {
                    row.executedAt = DateTime.fromISO(row.executedAt);
                })

                return res;
            });
    };
}
