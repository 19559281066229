import React from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CompanyData } from 'models/Company';

type CompanyFormProps = {
    data?: CompanyData,
    onFinish: (data: CompanyData) => void,
}

const CompanyForm = ({ data, onFinish }: CompanyFormProps) => {
    const { t } = useTranslation('company');

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new FormData(e.target as HTMLFormElement);
        const object = {} as CompanyData;
        formData.forEach((value, key) => object[key as keyof CompanyData] = value as string);
        onFinish(object);
    };

    return (
        <Form className="company-form" onSubmit={submit}>
            <Form.Group className="mb-3">
                <Form.Label>{t('company_name')}</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    defaultValue={data?.name}
                    required
                    maxLength={32}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t('company_address')}</Form.Label>
                <Form.Control
                    type="text"
                    name="address"
                    defaultValue={data?.address}
                    required
                    maxLength={256}
                />
            </Form.Group>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('city')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="city"
                            defaultValue={data?.city}
                            required
                            maxLength={32}
                        />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('zip_code')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="zipCode"
                            defaultValue={data?.zipCode}
                            required
                            maxLength={10}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('email')}</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            defaultValue={data?.email}
                            required
                        />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('phone')}</Form.Label>
                        <Form.Control
                            type="tel"
                            name="phone"
                            defaultValue={data?.phone}
                            required
                            maxLength={25}
                        />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('fax')}</Form.Label>
                        <Form.Control
                            type="tel"
                            name="fax"
                            defaultValue={data?.fax}
                            required
                            maxLength={25}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <div className="text-end">
                <Button type="submit">{t(!data ? 'create_company' : 'update')}</Button>
            </div>
        </Form>
    );

}

export default CompanyForm;
