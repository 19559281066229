import { AxiosResponse } from 'axios';
import BankAccountModel from 'models/BankAccount';
import { ApiBase } from 'utils/api';

export type IAccountApi = {
    all: () => Promise<AxiosResponse<BankAccountModel[]>>,
    create: (data: BankAccountModel) => Promise<AxiosResponse<BankAccountModel>>,
    update: (data: BankAccountModel) => Promise<AxiosResponse<BankAccountModel>>,
    delete: (id: string) => Promise<AxiosResponse>,
}

export default class AccountApi implements IAccountApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    all = () => {
        return this.base.axios.get('/bank/accounts');
    };

    create = (data: BankAccountModel) => {
        return this.base.axios.post('/bank/accounts', data);
    };

    update = (data: BankAccountModel) => {
        return this.base.axios.patch(`/bank/accounts/${data.uuid}`, data);
    };

    delete = (id: string) => {
        return this.base.axios.delete(`/bank/accounts/${id}`);
    };
}
