import { useRecoilState } from 'recoil';
import auth from 'context/atoms/auth';
import { parseJwt } from 'utils/common';
import Api from 'utils/api';

const useAuth = () => {
    const [my, setUser] = useRecoilState(auth);
    const isLogged = Object.keys(my).length > 0;

    const authToken = (token: string) => {
        localStorage.setItem('token', token);
        Api.authorize(token);

        let jwt = parseJwt(token);

        Api.setup(jwt.company.uuid, jwt.sub)
        setUser(jwt);
    };

    const login = (email: string, password: string) => {
        return Api.auth.login(email, password)
            .then(res => authToken(res.data.token));
    };

    const register = (email: string, password: string) => {
        return Api.auth.register(email, password)
            .then(res => authToken(res.data.token));
    };

    const logout = () => {
        Api.auth.logout()
            .then(() => {
                localStorage.removeItem('token');
                window.location.reload();
            })
    };

    const refresh = () => Api.auth.refresh().then(res => {
        localStorage.setItem('token', res.data.token);
        Api.authorize(res.data.token);
        setUser(parseJwt(res.data.token));
    });

    return { my, isLogged, login, logout, register, authToken, refresh };
};

export default useAuth;
