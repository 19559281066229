import { useEffect } from 'react';
import {
    useRecoilCallback,
    useRecoilRefresher_UNSTABLE,
    useRecoilValue,
    useResetRecoilState,
    useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import userList from 'context/atoms/userList';
import auth from 'context/atoms/auth';
import bidTabUpdates from 'context/atoms/bidTabUpdates';
import notificationList from 'context/atoms/notificationList';
import transactionFamily from 'context/atoms/transactionFamily';
import bidsActive from 'context/atoms/bidsActive';
import bidConfigList from 'context/atoms/bidConfigList';
import { usePusher } from 'context/Pusher';
import useNotify from 'hooks/useNotify';
import { useBidUpdater } from 'hooks/useBidUtils';
import { EventType, MessagePayload } from 'models/Messages';
import { BidStatus } from 'models/Bid';
import { UserType } from 'models/User';

const parseReadable = (data: any) => ({
    ...data,
    readable: JSON.parse(data.readable),
});

const Messages = () => {
    const my = useRecoilValue(auth)
    const setNotification = useSetRecoilState(notificationList)
    const resetUsers = useRecoilRefresher_UNSTABLE(userList);
    const refreshBids = useRecoilRefresher_UNSTABLE(bidsActive);
    const resetBids = useResetRecoilState(bidsActive);
    const resetBidGroups = useRecoilRefresher_UNSTABLE(bidConfigList);
    const { updateLocalBidStatus } = useBidUpdater();
    const setBidUpdates = useSetRecoilState(bidTabUpdates);
    const { channel, setPusherState } = usePusher();
    const { toast } = useNotify();
    const { t } = useTranslation('bids');
    const refreshActiveBids = () => {
        resetBids();
        refreshBids();
    };

    const addNotify = (type: EventType, payload: MessagePayload) => {
        setNotification(old => ({
            unreadEvents: my.sub === payload.author ? old.unreadEvents : old.unreadEvents + 1,
            events: [{
                uuid: Date.now().toString(),
                type: type,
                payload: parseReadable(payload),
                read: my.sub === payload.author,
                seen: my.sub === payload.author,
                createdAt: DateTime.now().toSQL(),
            }, ...old.events],
            hasNextPage: old.hasNextPage,
        }))
    };

    const markBidTab = (name: string) => {
        setBidUpdates(old => ({...old, [name]: true}));
    };

    const resetTransactions = useRecoilCallback(({ reset, refresh }) => (userId: string) => {
        reset(transactionFamily(userId));
        refresh(transactionFamily(userId));
    });

    useEffect(() => {
        if (!channel) {
            setPusherState({ profileId: my.sub });
            return;
        }

        channel.bind('USER_CREATED', (data: any) => {
            addNotify(EventType.USER_CREATED, data);
            resetUsers()
        });

        channel.bind('USER_UPDATED', (data: any) => {
            addNotify(EventType.USER_UPDATED, data);
            resetUsers()
        });

        channel.bind('USER_DELETED', (data: any) => {
            addNotify(EventType.USER_DELETED, data);
            resetUsers()
        });

        channel.bind('TRANSACTION_CREATED', (data: any) => {
            addNotify(EventType.TRANSACTION_CREATED, data);
            const readable = JSON.parse(data.readable);
            resetTransactions(readable.clientUuid);
            resetUsers();
        });

        channel.bind('BID_UPDATED', (data: any) => {
            addNotify(EventType.BID_UPDATED, data);
            const readable = JSON.parse(data.readable);

            switch (readable.newBidStatus) {
                case BidStatus.Draft:
                    markBidTab('draft');
                    break;
                case BidStatus.Ready:
                    markBidTab('ready');
                    break;
                case BidStatus.Manual:
                    if (readable.oldBidStatus !== BidStatus.Automatic)
                        markBidTab('on_alarm');
                    break;
                case BidStatus.Automatic:
                    break;
                case BidStatus.Canceled:
                    break;
                case BidStatus.CanceledManually:
                    break;
                case BidStatus.Outbid:
                    break;
                case BidStatus.Negotiation:
                    break;
                case BidStatus.Win:
                    if (my.type === UserType.Client)
                        toast.success(t('bids:your_bid_won'));
                    break;
                case BidStatus.WinNegotiation:
                    if (my.type === UserType.Client)
                        toast.success(t('bids:your_bid_won'));
                    break;
                case BidStatus.Lost:
                    break;
            }

            if (my.type === UserType.Client || my.sub === data.author) {
                resetBidGroups();
            }

            if (readable.newBidStatus === BidStatus.Draft && readable.oldBidStatus === BidStatus.Draft) {
                refreshActiveBids();
            } else if (my.sub !== data.author || readable.newBidStatus === BidStatus.Canceled) {
                updateLocalBidStatus(data.subject, readable.newBidStatus);
            }
        });

        // eslint-disable-next-line
    }, [channel, my.sub]);

    return null
};

export default Messages;
