import { AxiosResponse } from 'axios';
import { TransactionData, TransactionModel } from 'models/Transactions';
import { ApiBase } from 'utils/api';

export type ITransactionApi = {
    getTransactions: (userId: string, cursor: string) => Promise<AxiosResponse<TransactionModel[]>>,
    allTransactions: (cursor: string) => Promise<AxiosResponse<TransactionModel[]>>,
    createTransaction: (data: TransactionData) => Promise<AxiosResponse<TransactionModel>>,
}

export default class TransactionApi implements ITransactionApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    getTransactions = (userId: string, cursor: string) => {
        return this.base.axios.get<TransactionModel[]>(
            `/companies/${this.base.companyId}/users/${userId}/transactions?cursor=${cursor}`,
            {noSpinner: true}
        );
    };

    allTransactions = (cursor: string) => {
        return this.base.axios.get<TransactionModel[]>(
            `/companies/${this.base.companyId}/transactions?cursor=${cursor}`,
            {noSpinner: true}
        );
    };

    createTransaction = (data: TransactionData) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/transactions`, data);
    };
}
