import { ApiBase } from 'utils/api';

export default class AuthApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    register = (email: string, password: string) => {
        return this.base.axios.post<any>('/auth/register',
            { email: email, password: password }
        )
    };

    login = (email: string, password: string) => {
        return this.base.axios.post<any>('/auth/token',
            { email, password }
        )
    };

    logout = () => {
        return this.base.axios.get('/auth/token/logout')
    };

    refresh = () => {
        return this.base.axios.get<{token: string}>('/auth/token/refresh', {noSpinner: true})
    };

    sendChangePassToken = (email: string, lang: string) => {
        return this.base.axios.post('/users/password/reset',
            { email, lang }
        )
    };

    sendNewPass = (password: string, token: string) => {
        return this.base.axios.post('/users/password',
            { password, token }
        )
    };
}
