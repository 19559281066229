import { AxiosResponse } from 'axios';
import { Chat, Message } from 'models/Chat';
import { ApiBase } from 'utils/api';


export type IChatApi = {
    get: (status: string) => Promise<AxiosResponse<Chat[]>>,
    createWithClient: (chatId: string, name: string, clientId: string) => Promise<AxiosResponse>,
    createCustom: (chatId: string, name: string, userIds: string[]) => Promise<AxiosResponse>,
    send: (chatId: string, text: string, authorId: string) => Promise<AxiosResponse>,
    last: (chatId: string) => Promise<AxiosResponse<Message[]>>,
    prev: (chatId: string, time: string) => Promise<AxiosResponse<Message[]>>,
    deactivate: (chatId: string) => Promise<AxiosResponse>,
}

export default class ChatApi implements IChatApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    };

    get = (status: string) => {
        console.log(`get ${status} chat list`);

        const temp: Chat[] = []
        for (let i = 0; i < Math.random() * 4 + 2; i++) {
            const type = Math.random() > .5 ? 'lot' : 'bid';
            const title = type === 'lot' ? 'Lot' : 'Bid for';
            const id = Math.floor(Math.random() * 10000);
            const unreadCount = status === 'active' ? Math.floor(Math.random() * 4) : 0;
            const user = Math.random() > .5 ? {
                uuid: '68355d86-b942-43e9-81da-824e1653e9a9',
                name: 'Alt Pano',
            } : {
                uuid: '97a5f7c9-943e-4be3-a91b-a589fa4d117e',
                name: 'Test Admin',
            };
            temp.push({
                id: `${type}:${id}:${i}${i}${i}${i}${i}`,
                name: `${title} #${id}`,
                unreadCount,
                users: [
                    user,
                ]
            })
        }

        return new Promise<AxiosResponse<Chat[]>>((res) => res({data: temp} as AxiosResponse<Chat[]>))
        /*
        return this.base.axios.get(`/chats/${status}`, {noSpinner: true});
         */
    };

    createWithClient = (chatId: string, name: string, clientId: string) => {
        console.log('create client', chatId, name, clientId);
        return new Promise<AxiosResponse>(res => res({data: []} as AxiosResponse))
        /*
        return this.base.axios.post(`/chat/${chatId}`, {
            name,
            clientId
        }, {noSpinner: true})
        */
    };

    createCustom = (chatId: string, name: string, userIds: string[]) => {
        console.log('create custom', chatId, name, userIds);
        return new Promise<AxiosResponse>(res => res({data: []} as AxiosResponse))
        /*
        return this.base.axios.post(`/chat/${chatId}`, {
            name,
            userIds
        }, {noSpinner: true})
        */
    };

    last = (chatId: string) => {
        console.log('load last', chatId);
        return new Promise<AxiosResponse<Message[]>>((res) => res({data: Math.random() > .7 ? [] : (
            Math.random() > .5 ?
                [
                    {uuid: Math.random().toString(), author: {uuid: 'man', name: 'manager'}, text: 'Ok, answer is 42', createdAt: '2022-10-04T15:37:00.000000', unread: true},
                    {uuid: Math.random().toString(), author: {uuid: 'man', name: 'manager'}, text: 'Please wait for answer...', createdAt: '2022-10-04T15:29:00.000000', unread: false},
                    {uuid: Math.random().toString(), author: {uuid: this.base.profileId, name: 'my name'}, text: 'What is it?', createdAt: '2022-10-04T14:56:00.000000', unread: false},
                ]
                :
                [
                    {uuid: Math.random().toString(), author: {uuid: 'man', name: 'manager'}, text: 'Merry xxxmas tho', createdAt: '2022-10-02T17:58:00.000000', unread: false},
                    {uuid: Math.random().toString(), author: {uuid: this.base.profileId, name: 'my name'}, text: 'Wazzap', createdAt: '2022-10-01T17:46:00.000000', unread: false},
                    {uuid: Math.random().toString(), author: {uuid: 'man', name: 'manager'}, text: 'Hell o there', createdAt: '2022-09-24T12:33:00.000000', unread: false},
                    {uuid: Math.random().toString(), author: {uuid: this.base.profileId, name: 'my name'}, text: 'Hello', createdAt: '2022-09-24T11:20:00.000000', unread: false},
                ]
            )} as AxiosResponse<Message[]>))
        /*
        return this.prev(chatId,'9999-12-31 23:00:00.000000');
         */
    };

    send = (chatId: string, text: string, authorId: string) => {
        console.log('send', chatId, text, authorId);
        return new Promise<AxiosResponse>(res => res({data: []} as AxiosResponse))
        /*
        return this.base.axios.put(`/chat/${chatId}`, {
            text,
            authorId
        }, {noSpinner: true})
        */
    };

    prev = (chatId: string, time: string) => {
        console.log('load', chatId, 'since', time);

        const temp: any[] = []
        if (Math.random() > .5) {
            for (let i = Math.ceil(Math.random() * 4 + 1); i >= 0; i--) {
                temp.push({
                    uuid: Math.random().toString(),
                    author: {uuid: 'man', name: 'mario'},
                    text: `Message number ${i}`,
                    createdAt: `2022-10-10T15:3${i}:00.${Math.floor(Math.random() * 99999 + 1).toString().padStart(6, '0')}`,
                    unread: false
                })
            }
        }

        return new Promise<AxiosResponse<Message[]>>((res) => res({data: temp as Message[]} as AxiosResponse<Message[]>))
        /*
        return this.base.axios.get(`/chat/${chatId}?cursor=${time}`, {noSpinner: true})
         */
    };

    deactivate = (chatId: string,) => {
        console.log('deactivate', chatId);
        return new Promise<AxiosResponse<Message[]>>((res) => res({data: [] as Message[]} as AxiosResponse<Message[]>))
        /*
        return this.base.axios.delete(`/chat/${chatId}`, {noSpinner: true})
         */
    };
}
