import React from 'react'
import 'utils/i18n';

import { LoadingProvider } from './LoadingSpinner';
import { PusherProvider } from './Pusher';

function AppProviders({children}: any) {
    return (
        <LoadingProvider>
            <PusherProvider>
                {children}
            </PusherProvider>
        </LoadingProvider>
    )
}

export default AppProviders
