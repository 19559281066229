import React from 'react';

export interface SpinnerProps {
    loading?: boolean
}

const Spinner = ({loading = true}: SpinnerProps) => {
    if (!loading) {
        return null;
    }

    return (
        <div className="spinner-container">
            <div className="spinner" />
        </div>
    );
};

export default Spinner;
