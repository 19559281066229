import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import Api from 'utils/api';

const AuthTryLogin = () => {
    const {  authToken } = useAuth();

    useEffect(() => {
        const existingToken = localStorage.getItem('token');

        if (existingToken) {
            authToken(existingToken);
        }

        Api.subscribe('onError401', () => {
            localStorage.removeItem('token');
            window.location.reload();
        });
        // eslint-disable-next-line
    }, []);

    /* TODO still need refresh?
    useEffect(() => {
        if (!my.exp) return;

        const delay = my.exp * 1000 - Date.now();

        if (delay < 0) return;

        let id = setInterval(refresh, delay);
        return () => clearInterval(id);
        // eslint-disable-next-line
    }, [my.exp]);
    */

    return null;
};

export default AuthTryLogin;
