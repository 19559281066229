import { useEffect } from 'react';

const AppTheme = () => {
    const theme = localStorage.getItem('theme') || 'default';

    useEffect(() => {
        document.body.classList.add(`theme-${theme}`);

        return () => {
            document.body.classList.remove(`theme-${theme}`);
        }
    }, [theme]);

    return null;
};

export default AppTheme;
