import { AxiosResponse } from 'axios';
import { PlanModel } from 'models/User';
import { ApiBase } from 'utils/api';

export type IPlanApi = {
    all: () => Promise<AxiosResponse<PlanModel[]>>,
    get: (planId: string) => Promise<AxiosResponse<PlanModel>>,
    create: (data: PlanModel) => Promise<AxiosResponse<PlanModel>>,
    update: (data: PlanModel) => Promise<AxiosResponse<PlanModel>>,
    delete: (planId: string) => Promise<AxiosResponse>,
    assign: (userId: string, planId: string) => Promise<AxiosResponse<any>>,
}

export default class PlanApi implements IPlanApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    all = () => {
        return this.base.axios.get(`/companies/${this.base.companyId}/plans`, {noSpinner: true});
    };

    get = (planId: string) => {
        return this.base.axios.get(`/companies/${this.base.companyId}/plans/${planId}`);
    };

    create = (data: PlanModel) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/plans`, data);
    };

    update = (data: PlanModel) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/plans/${data.uuid}`, data);
    };

    delete = (planId: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/plans/${planId}`);
    };

    assign = (userId: string, planId: string) => {
        return this.base.axios.put(`/plans/assign`, {userId, planId});
    };
}
