import { atomFamily } from 'recoil';
import { addDays } from 'utils/common';

type Options = {
    client: string[][],
    manager: string[][],
    bidGroup: string[][],
    auction: string[],
    bidType: string[],
}

type Selected = {
    client: string[],
    manager: string[],
    bidGroup: string[],
    auction: string[],
    bidType: string,
    lotNumber: string,
}

export const bidOptionsFamily = atomFamily({
    key: 'bidOptions',
    default: {
        client: [],
        manager: [],
        bidGroup: [],
        auction: [],
        bidType: [],
    } as Options,
});

export const bidDatesFamily = atomFamily({
    key: 'bidDates',
    default: [addDays(new Date(), -1), addDays(new Date(), 7)] as [Date, Date],
});

export const bidSelectedFamily = atomFamily({
    key: 'bidSelected',
    default: {
        client: [],
        manager: [],
        bidGroup: [],
        auction: [],
        bidType: '',
        lotNumber: '',
    } as Selected,
});

export const bidGroupsByClientFamily = atomFamily({
    key: 'bidGroupsByClient',
    default: {} as {[key: string]: any},
});
