import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false, // react already safes from xss
            format: function(value, format, lng) {
                if (format === 'date') {
                    return new Intl.DateTimeFormat(lng).format(value);
                }

                if (format === 'weekday') {
                    return new Intl.DateTimeFormat(lng, { weekday: 'long' }).format(value)
                }

                if (format === 'currency') {
                    return new Intl.NumberFormat(lng, { style: 'currency', currency: 'JPY' }).format(value)
                }

                return value;
            }
        },

        // have a common namespace used around the full app
        ns: ['common'],
        defaultNS: 'common',
        fallbackNS: 'common',
    });

export default i18n;
