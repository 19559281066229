import { AxiosResponse } from 'axios';
import ConsigneeModel from 'models/Consignee';
import { ApiBase } from 'utils/api';

export type IConsigneeApi = {
    get: (userId: string) => Promise<AxiosResponse<ConsigneeModel[]>>,
    create: (userId: string, data: ConsigneeModel) => Promise<AxiosResponse<ConsigneeModel>>,
    update: (userId: string, data: ConsigneeModel) => Promise<AxiosResponse<ConsigneeModel>>,
    delete: (userId: string, consigneeId: string) => Promise<AxiosResponse>,
}

export default class ConsigneeApi implements IConsigneeApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    get = (userId: string) => {
        return this.base.axios.get(`/users/${userId}/consignees`, {noSpinner: true});
    };

    create = (userId: string, data: ConsigneeModel) => {
        return this.base.axios.post(`/users/${userId}/consignees`, data);
    };

    update = (userId: string, data: ConsigneeModel) => {
        return this.base.axios.put(`/users/${userId}/consignees/${data.uuid}`, data);
    };

    delete = (userId: string, consigneeId: string) => {
        return this.base.axios.delete(`/users/${userId}/consignees/${consigneeId}`);
    };
}
