import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Alert } from 'react-bootstrap';
import alertList from 'context/atoms/alertList';

type AlertBoxProps = {
    variant: 'full' | 'rounded',
    target: string,
}

const AlertBox = ({ target, variant }: AlertBoxProps) => {
    const [alerts , setAlerts] = useRecoilState(alertList);

    // On page change keep only persistent alerts
    useEffect(() => {
        return () => setAlerts(old => old.filter(alert => alert.persistent));
        // eslint-disable-next-line
    }, []);

    if (alerts.length === 0) {
        return null;
    }

    return (
        <div className={`alertbox-${variant}`}>
            {alerts.filter(alert => target === alert.target).map(alert => (
                <Alert
                    key={alert.id}
                    className={`alert-${variant}`}
                    variant={alert.type}
                    dismissible={alert.persistent}
                    onClose={() => setAlerts(old => old.filter(a => a.id !== alert.id))}
                >{alert.content}</Alert>
            ))}
        </div>
    );
};

export default AlertBox;
