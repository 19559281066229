import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { BidGroupConfigModel, BidModel, BidStatus } from 'models/Bid';
import LotModel from 'models/Lot';
import AuctionApi from './Auction';
import { ApiBase } from 'utils/api';

export type IBidApi = {
    get: (status: string[], from: string, to: string) => Promise<AxiosResponse<BidModel[]>>,
    getActive: () => Promise<AxiosResponse<BidModel[]>>,
    getGroups: () => Promise<AxiosResponse<BidGroupConfigModel[]>>,
    deleteGroup: (configId: string) => Promise<AxiosResponse>,
    create: (bidAmount: number, bidGroup: BidGroupConfigModel, curWinLimit: string, lot: LotModel) => Promise<AxiosResponse<BidModel>>,
    cancel: (bidId: string, lotId: string, client: boolean) => Promise<AxiosResponse>,
    setStatus: (bidId: string, status: BidStatus, price?: string) => Promise<AxiosResponse>,
}

export default class BidApi implements IBidApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    get = (status: string[], from: string, to: string) => {
        return this.base.axios.get<LotModel[]>(`/auctions/lots`, {
            params: {
                status,
                from,
                to,
            },
            noSpinner: true,
        })
            .then(res => {
                return {...res, data: this.formatBids(res.data)};
            });
    };

    getActive = () => {
        return this.base.axios.get<LotModel[]>(`/auctions/lots`, {
            params: {
                status: [BidStatus.Draft, BidStatus.Ready, BidStatus.Manual, BidStatus.Automatic, BidStatus.Negotiation],
                from: DateTime.now().plus({ days: -7 }).toISODate(),
                to: DateTime.now().plus({ days: 30 }).toISODate(),
            },
            noSpinner: true
        })
            .then(res => {
                return {...res, data: this.formatBids(res.data)};
            });
    };

    // TODO wait normal bids and remove formatter
    formatBids = (data: LotModel[]) => {
        const bids = [];

        for (let i = 0; i < data.length; i++) {
            let lot = AuctionApi.formatLot(data[i]);
            let client = {...lot.clients[0]} as any;
            let bid: BidModel = {...client.bid};
            bid.status = client.bid.status === 'ACTIVE' ? lot.details?.biddingStatus : client.bid.status;
            bid.lot = lot;
            client.bid = null;
            bid.client = client;
            bids.push(bid);
        }

        return bids;
    }

    getGroups = () => {
        return this.base.axios.get(`/bids/users/${this.base.profileId}/configs`, {noSpinner: true});
    };

    deleteGroup = (configId: string) => {
        return this.base.axios.delete(`/bids/users/${this.base.profileId}/configs/${configId}`);
    };

    create = (bidAmount: number, bidGroup: BidGroupConfigModel, curWinLimit: string, lot: LotModel) => {
        return this.base.axios.post<any, AxiosResponse<BidModel>>('/auctions/bids', {
            userId: this.base.profileId,
            bidAmount,
            config: {
                configId: bidGroup.uuid,
                name: bidGroup.name,
                winLimit: curWinLimit
            },
            lot:  {
                ...lot,
                date: lot.auctionDate,
                name: lot.auctionName,
                score: lot.auctionScore,
                startPrice: lot.auctionStartPrice,
                endPrice: lot.auctionEndPrice,
                status: lot.auctionStatus,
                images: lot.auctionImages,
            },
        }, {noSpinner: true}
        ).then(res => {
            // TODO remove after api
            res.data.status = BidStatus.Draft;
            return res;
        });
    };

    cancel = (bidId: string) => {
        return this.base.axios.delete(
            `/auctions/bids/${bidId}`,
            {noSpinner: true}
        )
    };

    setStatus = (bidId: string, status: BidStatus, price?: string) => {
        return this.base.axios
            .patch(
                `/auctions/bids/${bidId}/status`,
                {status, bidResultPrice: price},
                {noSpinner: true}
            )
    };
}
