import { atom, selector } from 'recoil';
import Api from 'utils/api';

const bidsActive = atom({
    key: 'bidsActive',
    default: selector({
        key: 'bidsActive/default',
        get: async () => {
            const res = await Api.bids.getActive();

            return res.data.reverse();
        },
    }),
});

export default bidsActive;
