export type MessagesAuthModel = {
    socketId: string
}

export type MessagePayload = {
    author: string,
    subject: string,
    readable: any,
}

export type Message = {
    uuid: string,
    type: EventType,
    payload: MessagePayload,
    read: boolean,
    seen: boolean,
    createdAt: string,
}

export type MessagesEventsModel = {
    unreadEvents: number,
    events: Message[],
    hasNextPage: boolean,
}

export enum EventType {
    USER_CREATED = 'USER_CREATED',
    USER_UPDATED = 'USER_UPDATED',
    USER_DELETED = 'USER_DELETED',
    BID_UPDATED = 'BID_UPDATED',
    TRANSACTION_CREATED = 'TRANSACTION_CREATED',
}
