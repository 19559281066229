import { AxiosResponse } from 'axios';
import LotModel from 'models/Lot';
import { decodeHtmlEntity, sortBidsByDate } from 'utils/common';
import { ApiBase } from 'utils/api';

type LotsFilter = {
    filter: any,
    data: LotModel[]
}

type CarModel = {
    modelName: string,
    totalQty: number,
}

export type IBikesApi = {
    brands: () => Promise<AxiosResponse<{ brandName: string }[]>>,
    models: (brand: string) => Promise<AxiosResponse<CarModel[]>>,
    lots: (brand: string, models: string[], qty: number) => Promise<AxiosResponse<LotsFilter>>,
}

export default class BikesApi implements IBikesApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    brands = () => {
        return this.base.axios.get<{ brandName: string }[]>('/auctions/bike/brands', {noSpinner: true});
    };

    models = (brand: string) => {
        return this.base.axios.get(`/auctions/bike/${brand}/models`);
    };

    lots = (brand: string, models: string[], qty=250) => {
        return this.base.axios
            .get<LotsFilter>(`/auctions/bike/${brand}/lots`, {
                params: {
                    modelNames: models,
                    numberOfLots: qty,
                }
            })
            .then(res => {
                for (let i = 0; i < res.data.data.length; i++) {
                    res.data.data[i] = BikesApi.formatLot(res.data.data[i])
                }

                res.data.filter.transmissionTypes = res.data.filter.transmissionTypes.map((e: string) => decodeHtmlEntity(e));
                res.data.filter.chassisNumbers = res.data.filter.chassisNumbers.map((e: string) => decodeHtmlEntity(e));

                res.data.filter.yearOfProduction[0] = Math.max(res.data.filter.yearOfProduction[0], 1920)

                return res;
            });
    };

    public static formatLot = (lot: any): LotModel => {
        lot.auctionStatus = lot.auctionStatus || 'closed';
        lot.auctionScore = lot.auctionScore || '999';
        lot.modelGrade = decodeHtmlEntity(lot.modelGrade);
        lot.chassisNumber = decodeHtmlEntity(lot.chassisNumber);
        lot.transmissionType = decodeHtmlEntity(lot.transmissionType);
        lot.info = decodeHtmlEntity(lot.info);
        lot.providerMileage = parseInt(lot.providerMileage);
        lot.engineSizeCc = parseInt(lot.engineSizeCc);
        lot.auctionStartPrice = parseInt(lot.auctionStartPrice);
        lot.auctionEndPrice = parseInt(lot.auctionEndPrice);
        lot.yearOfProduction = Math.max(parseInt(lot.yearOfProduction), 1920);

        lot.clients?.sort(sortBidsByDate);

        return lot
    }
}
