import { atom, selector } from 'recoil';
import { BidGroupConfigModel } from 'models/Bid';
import Api from 'utils/api';

const bidConfigList = atom<BidGroupConfigModel[]>({
    key: 'bidConfigs',
    default: selector({
        key: 'bidConfigsOuter',
        get: async () => (await Api.bids.getGroups()).data,
    }),
});

export default bidConfigList
