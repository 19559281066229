import { AxiosResponse } from 'axios';
import { PortModel, AgentContactModel, ShippingAgentModel, AgentInfoModel } from 'models/ShippingAgent';
import { ApiBase } from 'utils/api';

export type IShippingAgentApi = {
    get: () => Promise<AxiosResponse<ShippingAgentModel[]>>,
    create: (data: ShippingAgentModel) => Promise<AxiosResponse<ShippingAgentModel>>,
    update: (data: AgentInfoModel) => Promise<AxiosResponse<any>>,
    delete: (id: string) => Promise<AxiosResponse>,

    addLoadingPort: (agentId: string, portId: string) => Promise<AxiosResponse<any>>,
    delLoadingPort: (agentId: string, portId: string) => Promise<AxiosResponse>,

    createDestinationPort: (agentId: string, data: PortModel) => Promise<AxiosResponse<PortModel>>,
    updateDestinationPort: (agentId: string, data: PortModel) => Promise<AxiosResponse<any>>,
    removeDestinationPort: (agentId: string, portId: string) => Promise<AxiosResponse>,

    createContact: (agentId: string, data: AgentContactModel) => Promise<AxiosResponse<AgentContactModel>>,
    updateContact: (agentId: string, data: AgentContactModel) => Promise<AxiosResponse<any>>,
    removeContact: (agentId: string, contactId: string) => Promise<AxiosResponse>,

    getJapanPorts: () => Promise<AxiosResponse<PortModel[]>>,
}

export default class ShippingAgentApi implements IShippingAgentApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    get = () => {
        return this.base.axios.get(`/companies/${this.base.companyId}/shipping-agents`, {noSpinner: true});
    };

    create = (data: ShippingAgentModel) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/shipping-agents`, data);
    };

    update = (data: AgentInfoModel) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/shipping-agents/${data.uuid}`, data, {noSpinner: true});
    };

    delete = (id: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/shipping-agents/${id}`);
    };

    addLoadingPort = (agentId: string, portId: string) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/shipping-agents/${agentId}/loading-ports/${portId}`, undefined,{noSpinner: true})
    };

    delLoadingPort = (agentId: string, portId: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/shipping-agents/${agentId}/loading-ports/${portId}`, {noSpinner: true})
    };

    createDestinationPort = (agentId: string, data: PortModel) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/shipping-agents/${agentId}/destination-ports`, data, {noSpinner: true});
    };

    updateDestinationPort = (agentId: string, data: PortModel) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/shipping-agents/${agentId}/destination-ports/${data.uuid}`, data, {noSpinner: true});
    };

    removeDestinationPort = (agentId: string, portId: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/shipping-agents/${agentId}/destination-ports/${portId}`, {noSpinner: true});
    };

    createContact = (agentId: string, data: AgentContactModel) => {
        return this.base.axios.post(`/companies/${this.base.companyId}/shipping-agents/${agentId}/contacts`, data, {noSpinner: true});
    };

    updateContact = (agentId: string, data: AgentContactModel) => {
        return this.base.axios.patch(`/companies/${this.base.companyId}/shipping-agents/${agentId}/contacts/${data.uuid}`, data, {noSpinner: true});
    };

    removeContact = (agentId: string, contactId: string) => {
        return this.base.axios.delete(`/companies/${this.base.companyId}/shipping-agents/${agentId}/contacts/${contactId}`, {noSpinner: true});
    };

    getJapanPorts = () => {
        return this.base.axios.get('/japan-ports');
    };
}
