import React, { Suspense, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import useModal from 'hooks/useModal';
import AlertBox from 'components/Notifications/AlertBox';
import modal from 'context/atoms/modal';

const CustomModal = () => {
    const modals = useRecoilValue(modal);
    const { hideModal, _hideLastModal, _clearModals } = useModal();
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        return history.listen((loc, act) => {
            if (!modals.length) return;

            if (act === 'POP')
                _hideLastModal();

            if (act === 'PUSH' && modals[modals.length-1].page !== loc.pathname + loc.search)
                _clearModals();
        });
        // eslint-disable-next-line
    }, [modals]);

    return <>
        {modals.map((m, i) => {
            const { title, ...props } = m.modalProps;
            return (
                <Modal key={m.modalProps.id} {...props} centered show={m.show || i < modals.length-1} onHide={hideModal}>
                    {title &&
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    }
                    <Modal.Body>
                        <Suspense fallback={<div>{t('loading')}</div>}>
                            <AlertBox target="modal" variant="rounded"/>
                            {m.component}
                        </Suspense>
                    </Modal.Body>
                </Modal>
            )
        })}
    </>;
};

type LiveModalProps = {
    title: React.ReactNode,
} & React.ComponentProps<typeof Modal>

export const LiveModal = ({title, children, show, onHide, ...props}: LiveModalProps) => {
    const history = useHistory();

    useEffect(() => {
        if (!onHide || !show) return;

        history.push(history.location);

        return history.listen(() => onHide());
        // eslint-disable-next-line
    }, [show]);

    return (
        <Modal {...props} centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
};

export default CustomModal;
