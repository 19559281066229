import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import useInterval from 'hooks/useInterval';
import useNotify from 'hooks/useNotify';

const reload = () => window.location.reload();

const Version = () => {
    const [ version, setVersion ] = useState('');
    const [ newVersion, setNewVersion ] = useState('');
    const { alert } = useNotify();
    const { t } = useTranslation();

    useEffect(() => {
        fetch('/version.txt?t=' + Date.now())
            .then(response => response.text().then(setVersion));
    }, []);

    useInterval(() => {
        fetch('/version.txt?t=' + Date.now())
            .then(response => response.text().then(setNewVersion));
    }, 5*60000);

    useEffect(() => {
        if (newVersion === '' || newVersion === version) return;

        alert(
            <>{t('update_released')} <Button variant="light" onClick={reload}>{t('update')}</Button></>,
            { persistent: true, id: 'update_released' }
        );
        // eslint-disable-next-line
    }, [version, newVersion]);

    return null;
};

export default Version;
