import { atom } from 'recoil';
import { ReactNode } from 'react';

export type ModalProps = {
    id?: string,
    title?: ReactNode,
    size?: 'sm' | 'lg' | 'xl',
    fullscreen?: true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down';
};

type ModalData = {
    component: ReactNode,
    modalProps: ModalProps,
    show: boolean,
    page: string,
}

const modal = atom<ModalData[]>({
    key: 'modal',
    default: [],
});

export default modal;
