import axios, { AxiosInstance } from 'axios';
import AccountApi, { IAccountApi } from './Api/Accounts';
import AuctionApi, { IAuctionApi } from './Api/Auction';
import StatsApi, { IStatsApi } from './Api/Stats';
import BikesApi, { IBikesApi } from './Api/Bikes';
import AuthApi from './Api/Auth';
import CompanyApi, { ICompanyApi } from './Api/Company';
import ChatApi , { IChatApi } from './Api/Chats';
import ConsigneeApi, { IConsigneeApi } from './Api/Consignees';
import NotifyParties, { INotifyPartyApi } from './Api/NotifyParties';
import UserApi, { IUserApi } from './Api/Users';
import PlanApi, { IPlanApi } from './Api/Plans';
import ManagerApi, { IManagerApi } from './Api/Managers';
import BidApi, { IBidApi } from './Api/Bids';
import TransactionApi, { ITransactionApi } from './Api/Transactions';
import ProductApi, { IProductApi } from './Api/Products';
import LogsApi, { ILogsApi } from './Api/Logs';
import InvoiceApi, { IInvoiceApi } from './Api/Invoices';
import ShippingAgentApi, { IShippingAgentApi } from './Api/ShippingAgents';
import TransportAgentApi, { ITransportAgentApi } from './Api/TransportAgents';
import MessageApi, { IMessageApi } from './Api/Messages';
import PayerApi, { IPayerApi } from './Api/Payers';

export type {
    IAccountApi,
    IAuctionApi,
    IChatApi,
    ICompanyApi,
    IConsigneeApi,
    INotifyPartyApi,
    IUserApi,
    IPlanApi,
    IManagerApi,
    IBidApi,
    ITransactionApi,
    IProductApi,
    ILogsApi,
    IInvoiceApi,
    IShippingAgentApi,
    ITransportAgentApi,
    IMessageApi,
    IPayerApi,
    IStatsApi,
    IBikesApi,
};

declare module 'axios' {
    export interface AxiosRequestConfig {
        noSpinner?: boolean; // TODO Shouldn't be there, but I don't know the proper place
    }
}

export interface ApiBase {
    axios: AxiosInstance,
    companyId: string,
    profileId: string,
}

class Api implements ApiBase {
    readonly axios: AxiosInstance;

    companyId = '';
    profileId = '';

    private channels: {[name: string]: ((data: any) => void)[]} = {};

    accounts: IAccountApi;
    auction: IAuctionApi;
    stats: IStatsApi;
    bikes: IBikesApi;
    auth: AuthApi;
    transactions: ITransactionApi;
    bids: IBidApi;
    chats: IChatApi;
    company: ICompanyApi;
    consignees: IConsigneeApi;
    notifyParties: INotifyPartyApi;
    invoices: IInvoiceApi;
    logs: ILogsApi;
    managers: IManagerApi;
    plans: IPlanApi;
    products: IProductApi;
    users: IUserApi;
    shippingAgents: IShippingAgentApi;
    transportAgents: ITransportAgentApi;
    messages: IMessageApi;
    payers: IPayerApi;

    constructor() {
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_API_HOST,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        });

        this.axios.interceptors.request.use(
            config => {
                this.publish('beforeCall', config);
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        this.axios.interceptors.response.use(
            response => {
                this.publish('afterCall', response);
                return response;
            },
            error => {
                this.publish('afterCall', error);
                this.publish(`onError${error.response?.status || 'Network'}`, error);

                return Promise.reject(error);
            }
        );

        this.axios.defaults.paramsSerializer = function(params) {
            return Object.keys(params).map(key => {
                let str = encodeURIComponent(key) + '=';

                if (typeof params[key] === 'string' || typeof params[key] === 'number') {
                    return str + encodeURIComponent(params[key])
                }

                return params[key].map((val: string) => str + encodeURIComponent(val)).join('&');
            }).join('&');
        };

        this.accounts = new AccountApi(this);
        this.auction = new AuctionApi(this);
        this.stats = new StatsApi(this);
        this.bikes = new BikesApi(this);
        this.auth = new AuthApi(this);
        this.transactions = new TransactionApi(this);
        this.bids = new BidApi(this);
        this.chats = new ChatApi(this);
        this.company = new CompanyApi(this);
        this.consignees = new ConsigneeApi(this);
        this.notifyParties = new NotifyParties(this);
        this.invoices = new InvoiceApi(this);
        this.logs = new LogsApi(this);
        this.managers = new ManagerApi(this);
        this.plans = new PlanApi(this);
        this.products = new ProductApi(this);
        this.users = new UserApi(this);
        this.shippingAgents = new ShippingAgentApi(this);
        this.transportAgents = new TransportAgentApi(this);
        this.messages = new MessageApi(this);
        this.payers = new PayerApi(this);
    }

    authorize = (token: string) => {
        // @ts-ignore
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };

    setup = (companyId: string, userId: string) => {
        this.companyId = companyId;
        this.profileId = userId;
    };

    subscribe = (name: string, listener: (data: any) => void) => {
        if (!this.channels[name]) {
            this.channels[name] = []
        }

        this.channels[name].push(listener)
    };

    publish = (name: string, data: any) => {
        const channel = this.channels[name];

        if (channel?.length) {
            channel.forEach(listener => listener(data))
        }
    };
}

const api = new Api();

export default api;
