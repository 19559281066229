import React from 'react';
import Spinner from './Spinner';
import { useLoadingWatcher } from 'context/LoadingSpinner';

const Auto = () => {
    const { loading } = useLoadingWatcher();

    return (
        <Spinner loading={loading} />
    );
};

export default Auto;
