import { ReactNode } from 'react';
import { useSetRecoilState } from 'recoil';
import alertList from 'context/atoms/alertList';
import toastList from 'context/atoms/toastList';

export enum Type {
    Default = 'primary',
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'danger',
}

type AlertOptions = {
    id?: string,
    persistent?: boolean,
    delay?: number,
    target?: 'main' | 'modal', // any other places also can be added
}

type ToastOptions = {
    title?: string,
    id?: string,
    persistent?: boolean,
}

type BasicData = {
    id: string,
    content: ReactNode,
    type: Type,
    persistent: boolean,
}

export type AlertData = BasicData & {
    target: string,
}

export type ToastData = BasicData & {
    title: string,
}

const useNotify = () => {
    const setA = useSetRecoilState(alertList);
    const setT = useSetRecoilState(toastList);

    const showA = (type: Type, content: ReactNode, options: AlertOptions) => {
        const opts: AlertData = {
            content: content,
            type: type,
            id: options.id || Date.now().toString(),
            persistent: options.persistent || false,
            target: options.target || 'main',
        };

        const addIfNotDupe = (old: AlertData[]) => {
            for (let item of old) {
                if (item.content === content || item.id === opts.id) {
                    return old;
                }
            }

            return [...old, opts]
        }

        if (options.delay) {
            setTimeout(() => setA(addIfNotDupe), options.delay);
        } else {
            setA(addIfNotDupe);
        }
    };

    const removeA = (id: string) => {
        setA((old) => old.filter(alert => alert.id !== id));
    };

    const showT = (type: Type, content: ReactNode, options: ToastOptions) => {
        const opts: ToastData = {
            content: content,
            type: type,
            title: options.title || '',
            id: options.id || Date.now().toString(),
            persistent: options.persistent || false,
        };

        setT(old => {
            const last = old[old.length - 1];

            if (last && (last.content === content || last.id === opts.id)) {
                return old;
            }

            return [...old, opts]
        });
    };

    const alert   = (content: ReactNode, options?: AlertOptions) => showA(Type.Default, content, options || {});
    alert.success = (content: ReactNode, options?: AlertOptions) => showA(Type.Success, content, options || {});
    alert.info    = (content: ReactNode, options?: AlertOptions) => showA(Type.Info,    content, options || {});
    alert.warn    = (content: ReactNode, options?: AlertOptions) => showA(Type.Warning, content, options || {});
    alert.error   = (content: ReactNode, options?: AlertOptions) => showA(Type.Error,   content, options || {});
    alert.remove  = (id: string) => removeA(id);

    const toast   = (content: ReactNode, options?: ToastOptions) => showT(Type.Default, content, options || {});
    toast.success = (content: ReactNode, options?: ToastOptions) => showT(Type.Success, content, options || {});
    toast.info    = (content: ReactNode, options?: ToastOptions) => showT(Type.Info,    content, options || {});
    toast.warn    = (content: ReactNode, options?: ToastOptions) => showT(Type.Warning, content, options || {});
    toast.error   = (content: ReactNode, options?: ToastOptions) => showT(Type.Error,   content, options || {});

    return { alert, toast };
};

export default useNotify;
