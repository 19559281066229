import { atom, selector } from 'recoil';
import { MessagesEventsModel } from 'models/Messages';
import Api from 'utils/api';

const notificationList = atom({
    key: 'notificationList',
    default: selector({
        key: 'notificationListDefault',
        get: async () => {
            try {
                const data = (await Api.messages.last()).data;
                data.events = data.events.map(e => ({...e, seen: e.read}));
                return {...data, hasNextPage: true};
            } catch (err) {
                return { unreadEvents: 0, events: [], hasNextPage: false } as MessagesEventsModel;
            }
        }
    })
});

export default notificationList;
