import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { constSelector, useRecoilState, useRecoilValue } from 'recoil';
import {
    bidDatesFamily,
    bidGroupsByClientFamily,
    bidOptionsFamily,
    bidSelectedFamily
} from 'context/atoms/bidFilterFamily';
import bidsActive from 'context/atoms/bidsActive';
import bidsByStatus from 'context/selectors/bidsByStatus';
import bidsFamily from 'context/atoms/bidsFamily';
import auth from 'context/atoms/auth';
import useNotify from 'hooks/useNotify';
import { BidGroupConfigModel, BidModel, BidStatus, BidClientModel } from 'models/Bid';
import LotModel from 'models/Lot';
import { UserType } from 'models/User';
import { CustomSet, removeItemAtIndex, replaceItemAtIndex } from 'utils/common';
import Api from 'utils/api';


const comparator = (a: string[], b: string[]) => a[0] === b[0];

export const useBidUtils = (name: string, statuses?: BidStatus[], closed?: boolean) => {
    const [ options, setOptions ] = useRecoilState(bidOptionsFamily(name));
    const [ groups, setGroups ] = useRecoilState(bidGroupsByClientFamily(name));
    const [ dates, setDates ] = useRecoilState(bidDatesFamily(name));
    const [ selected, setSelected ] = useRecoilState(bidSelectedFamily(name));
    const bids = useRecoilValue(closed ? bidsFamily(name) : bidsByStatus(statuses));

    useEffect(() => {
        let keeper = {
            client:  new CustomSet<string[]>(comparator),
            manager: new CustomSet<string[]>(comparator),
            bidGroups: {
                all: new CustomSet<string[]>(comparator),
            } as {[key: string]: any},
            auction: new Set<string>(),
            bidType: new Set<string>(),
        };

        bids.forEach((bid: BidModel) => {
            keeper.auction.add(bid.lot.auctionName);
            bid.lot.details && keeper.bidType.add(bid.status);

            keeper.client.add([bid.client.uuid, `${bid.client.lastName} ${bid.client.firstName}`]);

            bid.client.managers?.forEach(manager => {
                keeper.manager.add([manager.uuid, `${manager.lastName} ${manager.firstName}`]);
            })

            keeper.bidGroups.all.add([bid.bidGroupConfig.uuid, bid.bidGroupConfig.name]);

            if (!(bid.client.uuid in keeper.bidGroups))
                keeper.bidGroups[bid.client.uuid] = new CustomSet<string[]>(comparator);

            keeper.bidGroups[bid.client.uuid].add([bid.bidGroupConfig.uuid, bid.bidGroupConfig.name]);
        });

        setGroups(Object.fromEntries(Object.entries(keeper.bidGroups).map(([id, set]) => [id, [...set]])));

        setOptions({
            client: [...keeper.client],
            manager: [...keeper.manager],
            bidGroup: [],
            auction: [...keeper.auction],
            bidType: [...keeper.bidType],
        });

        setSelected({
            client: [],
            manager: [],
            bidGroup: [],
            auction: [],
            bidType: '',
            lotNumber: '',
        });

        // eslint-disable-next-line
    }, [bids]);

    useEffect(() => {
        let bidGroup: string[][] = [];

        if (selected.client.length === 0) {
            bidGroup = groups.all || [];
        } else {
            selected.client.forEach(c => bidGroup = bidGroup.concat(groups[c]));
        }

        setOptions(old => ({
            ...old,
            bidGroup,
        }));
        // eslint-disable-next-line
    }, [selected.client]);

    const onInputChange = (values: any, name: any) => {
        setSelected({...selected, [name]: values});
    };

    const hasManager = (filter: string[], client: BidClientModel) => {
        if ('managers' in client) {
            for (let man of client.managers) {
                if (filter.includes(man.uuid)) {
                    return true;
                }
            }
        }

        return false;
    };

    const filtered = useMemo(() => bids.filter(bid => {
        return (
            (selected.auction.length === 0 || selected.auction.includes(bid.lot.auctionName)) &&
            (selected.client.length === 0 || selected.client.includes(bid.client.uuid)) &&
            (selected.manager.length === 0 || hasManager(selected.manager, bid.client)) &&
            (selected.bidGroup.length === 0 || selected.bidGroup.includes(bid.bidGroupConfig.uuid)) &&
            (selected.bidType.length === 0 || selected.bidType.includes(bid.status)) &&
            (selected.lotNumber === '' || bid.lot.lotNumber.startsWith(selected.lotNumber))
        )
    }), [bids, selected]);

    return {
        options,
        dates,
        selected,
        filtered,
        onInputChange,
        setDates,
    }
};

export const useBidUpdater = () => {
    const my = useRecoilValue(auth);
    const { t } = useTranslation('bids');
    // @ts-ignore
    const [, setBidsActive] = useRecoilState(my.sub ? bidsActive : constSelector([] as BidModel[]));  // TODO useSetRecoilState after fix https://github.com/facebookexperimental/Recoil/issues/439
    const { toast } = useNotify();

    const placeBid = (amount: number, bidGroup: BidGroupConfigModel, curWinLimit: string, lot: LotModel, onFinish: () => void) => {
        Api.bids.create(amount, bidGroup, curWinLimit, lot).then(() => {
            toast.success(t('bid_placed'));
            onFinish();
        })
    };

    const updateBidStatus = (bid: BidModel, status: BidStatus, price?: string) => {
        Api.bids.setStatus(bid.uuid, status, price);
        updateLocalBidStatus(bid.uuid, status);
    };

    const updateLocalBidStatus = (bidId: string, status: BidStatus) => {
        setBidsActive(old => {
            let idx = old.findIndex(b => b.uuid === bidId);

            return replaceItemAtIndex(old, idx, {
                ...old[idx],
                status: status,
            });
        });
    };

    const cancelBid = (bidId: string, lotId: string) => {
        Api.bids.cancel(bidId, lotId, my.type === UserType.Client);
        toast.success(t('bid_canceled'));

        setBidsActive(old => {
            let idx = old.findIndex(b => b.uuid === bidId);

            return removeItemAtIndex(old, idx);
        });
    };

    return {
        placeBid,
        updateBidStatus,
        updateLocalBidStatus,
        cancelBid,
    }
};
