import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import PasswordForm from 'components/PasswordForm/PasswordFrom';
import { useQueryParam, StringParam } from 'use-query-params';

const CreatePasswordPage = () => {
    const { t } = useTranslation('auth');
    const [ token ] = useQueryParam('token', StringParam);

    return (
        <>
            <Helmet title={t('crate_pass_title')} />

            <PasswordForm
                buttonText={t('create_pass_button')}
                subTitle={t('create_pass_subtitle')}
                title={t('crate_pass_title')}
                token={token} />
        </>
    );
};

export default CreatePasswordPage;
