import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import useAuth from 'hooks/useAuth';
import AlertBox from 'components/Notifications/AlertBox';
import useNotify from 'hooks/useNotify';

const LoginPage = () => {
    const { login } = useAuth();
    const { alert } = useNotify();
    const { t } = useTranslation('auth');
    const mailRef = React.useRef<HTMLInputElement>(null);
    const passRef = React.useRef<HTMLInputElement>(null);
    const [ error, setError ] = useState(false);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(false);
        alert.remove('login');

        if (!mailRef.current || !passRef.current) {
            return;
        }

        login(mailRef.current.value, passRef.current.value)
            .catch(res => {
                if (res.response?.status === 400) {
                    alert.error(t('error_credentials'), {id: 'login'});
                    setError(true);

                    if (!passRef.current) return;
                    passRef.current.value = '';
                } else {
                    alert.error(t('error_system'), {id: 'login'});
                }
            });
    };

    return (
        <div className="auth-form">
            <Helmet title={t('sign_in')}/>

            <Form onSubmit={onSubmit}>
                <div className="dirty">
                    <AlertBox variant="rounded" target="main" />
                </div>

                <h1>{t('sign_in')}</h1>

                <div className="subtitle">
                    {t('forgot_pass')} <Link to="/reset-pass">{t('click_here')}</Link>
                </div>

                <Form.Group className="mb-4">
                    <Form.Label>{t('email')}</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder={t('your_email')}
                        required
                        isInvalid={error}
                        name="email"
                        ref={mailRef}
                    />
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Label>{t('password')}</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={t('your_password')}
                        required isInvalid={error}
                        name="password"
                        ref={passRef}
                    />
                </Form.Group>

                <div className="d-grid">
                    <Button type="submit" size="lg">{t('sign_in_action')}</Button>
                </div>
            </Form>
        </div>
    )
};

export default LoginPage;
