import React, { createElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

type RouteProps = {
    component:  React.ComponentType<any>,
    path: string,
    exact?: boolean,
    permission?: string,
}

export function PrivateRoute({component, permission, ...rest}: RouteProps) {
    const { my, isLogged } = useAuth();

    return (
        <Route {...rest}
               render={props => isLogged ?
                   (
                       !permission || my.permissions.includes(permission) ?
                           createElement(component, props) :
                           <Redirect to="/" />
                   ) :
                   <Redirect to={{ pathname: '/login', state: { referer: props.location } }} />
               }
        />
    );
}

export function GuestRoute({component, ...rest}: RouteProps) {
    const { isLogged } = useAuth();

    return (
        <Route {...rest}
            render={(props: any) => !isLogged ?
                createElement(component, props) :
                <Redirect to={props.location.state?.referer || '/'} />}
        />
    );
}
