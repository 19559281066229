import { AxiosResponse } from 'axios';
import LotModel from 'models/Lot';
import { decodeHtmlEntity, sortBidsByDate } from 'utils/common';
import { ApiBase } from 'utils/api';

type LotsFilter = {
    filter: any,
    data: LotModel[]
}

type CarModel = {
    modelName: string,
    totalQty: number,
}

export type IAuctionApi = {
    brands: () => Promise<AxiosResponse<{ brandName: string }[]>>,
    models: (brand: string) => Promise<AxiosResponse<CarModel[]>>,
    lots: (brand: string, models: string[], qty: number) => Promise<AxiosResponse<LotsFilter>>,
    lotsByNumber: (id: string[]) => Promise<AxiosResponse<LotsFilter>>,
    marketPrice: (brandName: string, modelName: string, engineSizeCc: number, yearOfProduction: number) => Promise<AxiosResponse<LotModel[]>>,
    lot: (id: string) => Promise<AxiosResponse<LotModel>>,
    similarSold: (chassisNumber: string, year: number, mileage: number) => Promise<AxiosResponse<LotModel[]>>,
    getWatchList: () => Promise<AxiosResponse<LotModel[]>>,
    addToWatchList: (lot: LotModel) => Promise<AxiosResponse<LotModel>>,
    removeFromWatchList: (lotProviderId: string) => Promise<AxiosResponse>,
    getPhotos: (lotProviderId: string) => Promise<AxiosResponse<{auctionImages: string[]}>>,
}

export default class AuctionApi implements IAuctionApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    brands = () => {
        return this.base.axios.get<{ brandName: string }[]>('/auctions/brands', {noSpinner: true});
    };

    models = (brand: string) => {
        return this.base.axios.get(`/auctions/${brand}/models`);
    };

    lots = (brand: string, models: string[], qty=250) => {
        return this.base.axios
            .get<LotsFilter>(`/auctions/${brand}/lots`, {
                params: {
                    modelNames: models,
                    numberOfLots: qty,
                }
            })
            .then(res => {
                for (let i = 0; i < res.data.data.length; i++) {
                    res.data.data[i] = AuctionApi.formatLot(res.data.data[i])
                }

                res.data.filter.transmissionTypes = res.data.filter.transmissionTypes.map((e: string) => decodeHtmlEntity(e));
                res.data.filter.chassisNumbers = res.data.filter.chassisNumbers.map((e: string) => decodeHtmlEntity(e));

                res.data.filter.yearOfProduction[0] = Math.max(res.data.filter.yearOfProduction[0], 1920)

                return res;
            });
    };

    lotsByNumber = (id: string[]) => {
        return this.base.axios.get<LotsFilter>('/auctions/search-by-lot-numbers/lots',{
            params: {
                lotNumbers: id,
            }
        })
            .then(res => {
                for (let i = 0; i < res.data.data.length; i++) {
                    res.data.data[i] = AuctionApi.formatLot(res.data.data[i])
                }

                return res;
            });
    };

    similarSold = (chassisNumber: string, year: number, mileage: number) => {
        return this.base.axios.get<LotModel[]>('/auctions/check-previously-sold/lots',{
            params: {
                chassisNumber,
                year,
                mileage
            }
        })
            .then(this.processLots);
    };

    marketPrice = (brandName: string, modelName: string, engineSizeCc: number, yearOfProduction: number) => {
        return this.base.axios.get<LotModel[]>('/auctions/marketprice', {
            params: {
                brandName,
                modelName,
                engineSizeCc,
                yearOfProduction,
            }
        })
            .then(this.processLots);
    };

    lot = (id: string) => {
        return this.base.axios.get<LotModel>(`/auctions/lots/${id}`)
            .then(res => {
                res.data = AuctionApi.formatLot(res.data)

                return res;
            });
    };

    getWatchList = () => {
        return this.base.axios.get(`/users/lots/${this.base.profileId}/watchlist`)
            .then(this.processLots);
    };

    addToWatchList = (lot: LotModel) => {
        return this.base.axios.post(`/users/lots/${this.base.profileId}/watchlist`, lot, {noSpinner: true});
    };

    removeFromWatchList = (lotProviderId: string) => {
        return this.base.axios.delete(`/users/lots/${this.base.profileId}/watchlist/${lotProviderId }`, {noSpinner: true});
    };

    getPhotos = (lotProviderId: string) => {
        return this.base.axios.get(`/auctions/lots/${lotProviderId}/photos`);
    };

    public static formatLot = (lot: any): LotModel => {
        lot.auctionStatus = lot.auctionStatus || 'active'; // TODO wait for backend and delete

        lot.modelGrade = decodeHtmlEntity(lot.modelGrade);
        lot.chassisNumber = decodeHtmlEntity(lot.chassisNumber);
        lot.transmissionType = decodeHtmlEntity(lot.transmissionType);
        lot.info = decodeHtmlEntity(lot.info);

        lot.providerMileage = parseInt(lot.providerMileage);
        lot.engineSizeCc = parseInt(lot.engineSizeCc);
        lot.auctionStartPrice = parseInt(lot.auctionStartPrice);
        lot.auctionEndPrice = parseInt(lot.auctionEndPrice);
        lot.yearOfProduction = Math.max(parseInt(lot.yearOfProduction), 1920);

        lot.clients?.sort(sortBidsByDate);

        return lot
    }

    private processLots = (res: AxiosResponse<LotModel[]>) => {
        for (let i = 0; i < res.data.length; i++) {
            res.data[i] = AuctionApi.formatLot(res.data[i])
        }

        return res;
    }
}
