import { AxiosResponse } from 'axios';
import PayerModel from 'models/Payer';
import { ApiBase } from 'utils/api';

export type IPayerApi = {
    get: (userId: string) => Promise<AxiosResponse<PayerModel[]>>,
    create: (userId: string, data: PayerModel) => Promise<AxiosResponse<PayerModel>>,
    update: (userId: string, data: PayerModel) => Promise<AxiosResponse<PayerModel>>,
    delete: (userId: string, consigneeId: string) => Promise<AxiosResponse>,
}

export default class PayerApi implements IPayerApi {
    private base: ApiBase;

    constructor(base: ApiBase) {
        this.base = base;
    }

    get = (userId: string) => {
        return this.base.axios.get(`/users/${userId}/payers`, {noSpinner: true});
    };

    create = (userId: string, data: PayerModel) => {
        return this.base.axios.post(`/users/${userId}/payers`, data);
    };

    update = (userId: string, data: PayerModel) => {
        return this.base.axios.put(`/users/${userId}/payers/${data.uuid}`, data);
    };

    delete = (userId: string, payerId: string) => {
        return this.base.axios.delete(`/users/${userId}/payers/${payerId}`);
    };
}
