import React, { createContext, useContext, useState, useEffect } from 'react';
import Pusher, { Channel } from 'pusher-js';
import Api from 'utils/api';

type PusherStateType = {
    profileId: string,
    pusher?: Pusher,
    channel?: Channel,
}

type PusherContextType = {
    channel: Channel,
    setPusherState: (val: PusherStateType) => void,
}

const PusherContext = createContext({} as PusherContextType);

export const PusherProvider = (props: any) => {
    const [state, setPusherState] = useState({} as PusherStateType);

    useEffect(() => {
        if (!state.profileId) return
        Pusher.logToConsole = true; // TODO delete

        const pusher = new Pusher('4f25c3ac4378395d7abf', {
            cluster: 'ap3',
            authorizer: () => ({
                authorize: async (socketId: string, callback) => {
                    let auth = (await Api.messages.auth({socketId})).data.authString;
                    callback(null, { auth });
                }
            })
        });

        const channel = pusher.subscribe(`private-${state.profileId}`);

        setPusherState({
            profileId: state.profileId,
            pusher,
            channel,
        })
        // eslint-disable-next-line
    }, [state.profileId]);

    return (
        <PusherContext.Provider value={{ channel: state.channel, setPusherState }} {...props} />
    );
};

export const usePusher = () => useContext(PusherContext);
